type SortDictionary = Record<string, number>;

export const getSortDictionary = (settings?: string | null): SortDictionary => {
  const defaultSettings = {
    'No mat': 1,
    Mat: 2,
    'Wide mat': 3,
    'Float mount': 4,
    'Bobby mat': 5,
  };
  if (!settings) return defaultSettings;
  try {
    const parsedSettings = JSON.parse(settings);
    // Convert array of the strings to the object where the key is the each array item
    // and the value is an index in the array that'll be used to sort mate options
    return parsedSettings.reduce(
      (a: SortDictionary, v: string, i: number) => ({ ...a, [v.toLowerCase()]: i }),
      {}
    );
  } catch {
    return defaultSettings;
  }
};

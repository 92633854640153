import styled from 'styled-components';
import { colors, getMapping } from '@/themes/colorsMapping';

const buttonColors = getMapping('contained', 'primary');
const FloatingButtonStyles = `
`;

const CtaButtonContainer = styled.div`
  display: flex;
  position: relative;
  gap: 10px;
  align-items: center;
  width: 100%;
`;
CtaButtonContainer.displayName = 'CtaButtonContainer';

const CtaButton = styled.button<{ obscure?: boolean }>`
  &:disabled {
    opacity: 0.3;
  }
  border-radius: 100px;
  padding: 6px 0;
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  border-color: ${buttonColors.borderColor};
  opacity: ${({ obscure }) => (obscure ? 0.15 : 1)}!important;
  transition: all 0.25s ease-in-out;
  height: 62px;

  @media (min-width: 800px) {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 20px !important;
  }

  :hover {
    background-color: #2900a3;
    box-shadow: 0 0 0 3px #fff, 0 0 0 6px rgba(51, 0, 204, 0.2);
  }

  > span {
    &::before {
      transition: all 0.25s ease-in-out;
      content: '';
      display: inline-block;
      width: 12px;
      height: 1px;
      background-color: currentColor;
      margin: 0 5px;
      vertical-align: middle;
      opacity: 0.75;
    }
  }
`;

const FloatingContainer = styled.div`
  left: 0;
  width: 100dvw;
  z-index: 10;
  padding: 15px 20px 25px;
  display: flex;
  align-items: center;
  position: fixed;
  transition: all 0.5s ease-in-out;
  background-color: ${colors.white};
`;

const FloatingCTA = styled(FloatingContainer)<{ fixed: boolean }>`
  bottom: 0;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.15);
  transform: translateY(${(props) => (props.fixed ? '0%' : '100%')});
  @media (min-width: 800px) {
    display: none;
  }

  > div {
    flex: 1;
    padding-right: 25px;
    display: block;
  }
  strong {
    font-size: 18px;
    display: block;
    margin-bottom: 5px;
  }

  > button {
    transition: all 0.25s ease-in-out;
    margin-bottom: 0;
    white-space: nowrap;
    padding-left: 15px;
    padding-right: 15px;
    flex: 0;
    > span {
      display: inline;
    }
  }

  .tmplt-product__reviews-total-wrapper {
    margin-bottom: 0;
    min-width: 93px;
    transform: scale(0.75);
    transform-origin: left;
    max-width: 100px;
  }
  .RatingStars--small {
    white-space: nowrap;
  }
`;
FloatingCTA.displayName = 'FloatingCTA';

const UploadButton = styled.button`
  width: 100%;
  height: 50px;
  background-color: ${buttonColors.backgroundColor};
  border: 1px solid transparent;
  color: ${buttonColors.color};
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transform: translate3d(0, 0, 0);
  transition: all 0.5s ease-in-out;
  font-weight: 450;
  ${FloatingButtonStyles};

  svg {
    height: 25px;
    width: 30px;
    margin-right: 5px;
  }

  :hover {
    background-color: ${colors.purpleHoverLight};
  }
`;

const FloatingCtaButton = styled(CtaButton)`
  margin-bottom: 0 !important;
  ${FloatingButtonStyles};

  :hover {
    background-color: ${colors.purpleHoverLight};
  }

  @media (max-width: 799px) {
    height: 50px;
  }
`;

const FloatingCtaStyled = styled(FloatingContainer)<{ fixed?: boolean }>`
  top: ${(props) => (props.fixed ? '0' : '-65px')};
  background-color: ${colors.blackText};
  gap: 10px;
  padding: 8px 15px;
  justify-content: center;

  @media (max-width: 799px) {
    background-color: ${colors.white};
    top: auto;
    bottom: ${(props) => (props.fixed ? '0' : '-65px')};
    box-shadow: 0px 0px 3px 0 rgba(0, 0, 0, 0.15);
  }
`;

const Discount = styled.span`
  font-size: 22px;
  font-weight: 500;
  @media (min-width: 800px) {
    color: ${colors.white};
  }

  @media (min-width: 350px) {
    text-wrap: nowrap;
  }
`;

export {
  CtaButtonContainer,
  CtaButton,
  FloatingCTA,
  FloatingCtaStyled,
  UploadButton,
  Discount,
  FloatingCtaButton,
};

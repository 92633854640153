import * as React from 'react';

const FrameIcon: React.FC = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 3H5.5C4.39543 3 3.5 3.89543 3.5 5V19C3.5 20.1046 4.39543 21 5.5 21H19.5C20.6046 21 21.5 20.1046 21.5 19V5C21.5 3.89543 20.6046 3 19.5 3Z"
        stroke="#262626"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.44444 6.75H17.5556C17.9967 6.75 18.25 7.06656 18.25 7.33333V16.6667C18.25 16.9334 17.9967 17.25 17.5556 17.25H7.44444C7.00326 17.25 6.75 16.9334 6.75 16.6667V7.33333C6.75 7.06656 7.00326 6.75 7.44444 6.75Z"
        stroke="#262626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

FrameIcon.displayName = 'FrameIcon';
export default FrameIcon;

import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Metafields, Product } from '@/types/ecommerce.types';
import { RootState } from '@/store';
import { withQueryOnlyData } from '../data/withQueryOnlyData';
import getProductDescription from '@/components/product/shared/getProductDescription';
import { getMetafieldV2 } from '@/utils/utils';

type PropTypes = {
  product: Product;
  shopMetafields?: Metafields;
  showSize?: boolean;
  className?: string;
};

const AttributionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 3px 0 2px 0;
  font-size: 14px;

  @media (min-width: 768px) {
    margin: 0 0 10px 0;
  }

  img {
    max-width: 100px;
    max-height: 15px;
  }

  strong {
    font-size: 16px;
    font-weight: 500;
    @media (min-width: 768px) {
      font-size: 18px;
    }
  }
`;

const AttributionText = styled.span`
  margin-right: 6px;
  margin-top: -2px;
`;

const AttributionLogo = styled.img`
  height: 20px;
`;

const ProductHeader = ({ product, shopMetafields, showSize, className }: PropTypes) => {
  const { productTitle: productTitleFromRedux } = useSelector((state: RootState) => state.product);
  const { size: productSize } = getProductDescription(product, shopMetafields);
  const size = productSize && showSize ? productSize.split(' ')[0] : '';
  const attributionLogo = getMetafieldV2('attribution_logo', product?.metafields);
  const attributionText = getMetafieldV2('attribution_text', product?.metafields);
  const attributionPrefixLabel = getMetafieldV2('attribution_prefix_label', product?.metafields);
  const attributionLogoParsed = attributionLogo ? JSON.parse(attributionLogo) : null;

  return (
    <>
      <header
        className={`tmplt-product__header ${className}`}
        style={{ justifyContent: 'space-between' }}
      >
        <h1
          data-testid="product-title"
          className="tmplt-product__title typo__header typo__header--large typo__header--bold"
        >
          {size} {productTitleFromRedux || product?.title}
        </h1>
      </header>
      {(attributionLogo || attributionText) && (
        <AttributionWrapper>
          <AttributionText>{attributionPrefixLabel ?? 'Designed by'}</AttributionText>
          {attributionLogo ? (
            <AttributionLogo src={attributionLogoParsed[0]?.src} alt="Attribution Logo" />
          ) : (
            <strong>{attributionText}</strong>
          )}
        </AttributionWrapper>
      )}
    </>
  );
};

ProductHeader.displayName = 'ProductHeader';
export default React.memo(withQueryOnlyData(ProductHeader));

import { Link } from '@reach/router';
import React from 'react';
import { useSelector } from 'react-redux';
import { Collection } from 'shopify-storefront-api-typings';
import styled, { css } from 'styled-components';
import { RootState } from '@/store';
import { withQuery } from '../data/withQuery';
import { Metafields, Product, ProductTypes } from '@/types/ecommerce.types';
import {
  getCurrentVariant,
  getUrlByColor,
  handleizeProductType,
  mapProductEdges,
} from '@/utils/utils';
import FetchProduct from './FetchProduct';
import { ProductOptionsTitle } from './ProductOptions.styles';
import { colors } from '@/themes/colorsMapping';
import getProductDescription from '../shared/getProductDescription';

const StyledLink = styled(Link)<{ $availableToSell?: boolean }>`
  width: 28px;
  height: 28px;
  ${(props) =>
    !props.$availableToSell &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
    `}
`;
StyledLink.displayName = 'ColorSwatch';

const SwatchesContainer = styled.div`
  margin-bottom: 20px;
`;

const SwatchesList = styled.ul`
  margin-top: 13px;
  position: relative;
  overflow: visible;
  display: flex !important;
  @media (min-width: 800px) {
    margin-top: 10px;
  }

  .tmplt-product__variants-item--sold-out {
    border-radius: 20px;
  }

  .tmplt-product__connected-collection-item {
    width: auto;
    &.tmplt-product__connected-collection-item--current::after {
      border-color: ${colors.blackText};
    }
    &::after {
      top: -5px;
      left: -5px;
      width: 36px !important;
      height: 36px !important;
    }
  }
`;

export type Color = {
  title: string;
  handle: string;
  color: string;
  colorName: string;
  availableToSell: boolean;
};

type PropTypes = {
  product: Product;
  colorCollection: Collection;
  className: string;
  pagePath?: string;
  showDefaultColor?: boolean;
  openStyle?: boolean;
  sectionTitle?: string;
  shopMetafields: Metafields;
};

const ProductSwatches = ({
  product,
  colorCollection,
  className,
  pagePath = '/products',
  showDefaultColor,
  openStyle,
  sectionTitle,
  shopMetafields,
}: PropTypes) => {
  const variants = product?.variants?.edges;
  const currentVariant = getCurrentVariant(variants);
  const productType = handleizeProductType(product?.productType);
  const frameSize = currentVariant?.selectedOptions[0].value;
  const { type } = useSelector((state: RootState) => state.product);
  const colors: Color[] = mapProductEdges(colorCollection);
  const isGallery = type === ProductTypes.GALLERY;

  const {
    color: productColor,
    size: productSize,
    printSize,
  } = getProductDescription(product, shopMetafields);

  if (colors.length <= 1 && !showDefaultColor) {
    return null;
  }

  if (
    !product ||
    !currentVariant ||
    !colorCollection ||
    (productType !== ProductTypes.PICTURE &&
      productType !== ProductTypes.GALLERY &&
      productType !== ProductTypes.FRAMEDCANVAS &&
      productType !== ProductTypes.BLACKLABEL &&
      !showDefaultColor)
  ) {
    return null;
  }

  return (
    <SwatchesContainer className={className}>
      {sectionTitle && (
        <ProductOptionsTitle>
          {sectionTitle}: {'\u00A0'}
          <span>{isGallery ? printSize : productColor}</span>
        </ProductOptionsTitle>
      )}
      <SwatchesList className="tmplt-product__connected-collection-list">
        {colors?.map((color) => {
          const url = getUrlByColor({
            pagePath,
            color: color.handle,
            productType,
            frameSize,
            openStyle,
          });

          return (
            <li
              key={color.handle}
              className={`tmplt-product__connected-collection-item ${
                product.handle === color.handle
                  ? 'tmplt-product__connected-collection-item--current'
                  : ''
              }`}
            >
              <StyledLink
                $availableToSell={color.availableToSell}
                to={url}
                onClick={(e) => {
                  if (!color.availableToSell) e.preventDefault();
                }}
                className={`tmplt-product__connected-collection-link color-${color?.colorName.toLowerCase()}${
                  !color?.availableToSell ? ' tmplt-product__variants-item--sold-out' : ''
                }`}
                style={{ backgroundColor: color?.color }}
                title={color.title}
              >
                {color.title}
              </StyledLink>
              {type === ProductTypes.GALLERY ? <FetchProduct handle={color.handle} /> : null}
            </li>
          );
        })}
      </SwatchesList>
    </SwatchesContainer>
  );
};

ProductSwatches.displayName = 'ProductSwatches';
export default withQuery(ProductSwatches);

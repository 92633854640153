import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  html, body, #root {
    height: 100%;
  }
  html {
    scroll-behavior: smooth;
  }
  body {
    background-color: #fff;
  }
  :root {
    --reviewsio--background-color: transparent !important;
  }
  .affirm-modal-trigger {
    color: #3300cc;
  }

  body.template-product-builder main {
    @media (min-width: 800px) {
      overflow: visible !important;
    }
  }

  .main-header__wrapper.gallery-header {
    @media (max-width: 800px) {
      background: none !important;
    }
  }

  .tmplt-product__add-to-cart {
    margin-bottom: 10px;
  }
  .tmplt-product__icons-wrapper {
    margin-top: 30px;
  }
  .affirm-as-low-as {
    font-size: 16px;
    @media (max-width: 800px) {
      font-size: 14px;
    }
  }
      
  .tmplt-product__variants-label {
    padding-top: 8px;
    padding-bottom: 8px;
    &:hover {
      cursor: pointer;     
    }
  }
    
  .headroom {
    z-index: 7 !important;
  }
`;

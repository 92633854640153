import React from 'react';
import { getMetafieldV2 } from '../../../utils/utils';
import RelatedProductCard from './RelatedProductCard';
import { Product } from '../../../types/ecommerce.types';
import { useQuery } from '@apollo/client';
import { GET_COLLECTION_BY_HANDLE, GET_PRODUCT_RECOMMENDATIONS } from '../data/productQuery';
import * as S from './RelatedProducts.styles';
import { withQuery } from '../data/withQuery';
import { CollectionsData, RelatedProductsProps } from './RelatedProducts.types';
import Loading from '../../templates/icons/Loading';
import { useInView } from 'react-intersection-observer';
import TrackingService from '../../../services/TrackingService';
import { ItemListAttribution } from '../../../services/TrackingService.types';
import Container from '../shared/Container';
import SectionTitle from '../shared/SectionTitle';

const RelatedProducts: React.FC<RelatedProductsProps> = ({
  product,
  shopMetafields,
  shopMetaobjects,
}) => {
  const collectionHandle = getMetafieldV2('ymal_collection', product?.metafields);
  const { data: dataCollection, loading: loadingCollection } = useQuery<CollectionsData>(
    GET_COLLECTION_BY_HANDLE,
    {
      variables: {
        handle: collectionHandle,
      },
      skip: !collectionHandle,
    }
  );

  const ymalProdBGMeta = String(getMetafieldV2('product_listings', shopMetafields));
  const ymalProdBGClass = ymalProdBGMeta.replace('#', '').toLowerCase();

  const { data: dataRecommendations, loading: loadingRecommendations } = useQuery<{
    productRecommendations: Product[];
  }>(GET_PRODUCT_RECOMMENDATIONS, {
    variables: { productId: product?.id },
    skip: !!collectionHandle || !product?.id,
  });

  const removeCurrentProduct = (products: Product[] | undefined): Product[] => {
    if (!products) return [];
    return products.filter((p) => p.id !== product?.id);
  };

  const productRecommendations = removeCurrentProduct(dataRecommendations?.productRecommendations);

  const productCollections = removeCurrentProduct(
    dataCollection?.collectionByHandle?.products?.edges.map(
      (item) => item.node as unknown as Product
    )
  );

  const ymalType =
    productCollections && productCollections.length > 0 ? 'collection' : 'recommended';
  const productsArr = ymalType === 'collection' ? productCollections : productRecommendations;

  // Limit to 4 products
  const limitedProductsArr = productsArr.slice(0, 4);

  const itemListAttribution: Partial<ItemListAttribution> = {
    item_list_name: `YMAL: ${product?.title || 'unknown'}`,
    item_list_id:
      ymalType === 'collection'
        ? 'ymal:' + product?.handle + '|type=collection|source=' + collectionHandle
        : 'ymal:' + product?.handle + '|type=recommended|source=' + product?.handle,
  };

  const title = getMetafieldV2('pdp_ymal_title', product?.metafields) ?? 'You may also like';

  const { ref } = useInView({
    triggerOnce: true,
    rootMargin: '0px',
    threshold: 0.75,
    onChange: (inView) => {
      if (inView) {
        TrackingService.ga4Track('view_item_list', limitedProductsArr, 1, {
          attributionOverride: itemListAttribution,
        });
      }
    },
  });

  if (!product) {
    return null;
  }

  if (!loadingCollection && !loadingRecommendations && limitedProductsArr.length === 0) {
    return null;
  }

  return (
    <S.Wrapper>
      <Container>
        <SectionTitle title={title} />

        {loadingCollection || loadingRecommendations ? (
          <S.LoadingContainer>
            <Loading />
          </S.LoadingContainer>
        ) : (
          <S.RelatedProductsGrid ref={ref}>
            {limitedProductsArr.map((item, index) => (
              <RelatedProductCard
                key={item.id}
                product={item}
                pageProduct={product}
                index={index}
                ymalType={ymalType}
                ymalProdBGClass={ymalProdBGClass}
                shopMetafields={shopMetafields}
                shopMetaobjects={shopMetaobjects}
              />
            ))}
          </S.RelatedProductsGrid>
        )}
      </Container>
    </S.Wrapper>
  );
};

RelatedProducts.displayName = 'RelatedProducts';
export default withQuery(RelatedProducts);

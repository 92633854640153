const LOCAL_STORAGE_S3_SIGNATURE = 'frameology-upload-signature';
type Signature = {
  ts: number;
  url: string;
  fields: Record<string, string>;
};
const getStoredSignature = () => {
  try {
    const signatureData = localStorage.getItem(LOCAL_STORAGE_S3_SIGNATURE);
    if (!signatureData) return;
    return JSON.parse(signatureData);
  } catch {
    return;
  }
};

const getSignature = async (): Promise<Signature | undefined> => {
  const signature = getStoredSignature();

  if (signature && signature.ts > Date.now()) {
    return signature;
  }

  const data = await fetch('http://pod-api.fly.dev/api/v1/upload');
  const signatureData = await data.json();

  if (signatureData) {
    localStorage.setItem(LOCAL_STORAGE_S3_SIGNATURE, JSON.stringify(signatureData));
    return signatureData;
  }
  return;
};

const uploadImageToS3 = async (key: string, file?: File | Blob | null) => {
  if (!file) return;
  const signature = await getSignature();

  if (!signature) return;

  const formData = new FormData();

  Object.keys(signature.fields).forEach((field) => {
    formData.append(field, signature.fields[field]);
  });

  formData.append('Content-Type', 'image/jpg');
  formData.set('key', `uploads/${key}.jpg`);
  formData.append('file', new Blob([file]));

   await fetch(signature.url, { method: 'POST', body: formData });
   return s3URL(key);
};

const s3URL = (id: string) => {
  return `https://frameology-framebuilder-poc.s3.us-east-1.amazonaws.com/uploads/${id}.jpg`;
};

export { uploadImageToS3, s3URL };

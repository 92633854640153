import { Color, ColorsMapping, Format, Key } from './types';

const DEFAULT = 'default';

export const colors: { [key: string]: string } = {
  white: '#fff',
  black: '#000',
  purple: '#3300cc',
  purpleLight: '#e1daf8',
  gray: '#f1f0ec',
  mediumGray: '#e3e0d8',
  darkGray: '#CECCB5',
  blackText: '#262626',
  blackHover: '#cccccc', // black opacity 15%
  purpleHover: 'rgba(51, 0, 204, 0.15)', // purple opacity 15%
  purpleHoverLight: '#7a59e0',
  green: '#0AFF99',
  divider: '#e0e0e0',
};

const mapping: Partial<Record<Key, ColorsMapping>> & Record<typeof DEFAULT, ColorsMapping> = {
  contained_primary: {
    color: colors.white,
    borderColor: colors.purple,
    backgroundColor: colors.purple,
    hoverColor: colors.white,
    hoverBackgroundColor: colors.purpleHover,
    hoverBorderColor: 'transparent',
  },
  outlined_primary: {
    color: colors.purple,
    borderColor: colors.purple,
    backgroundColor: 'transparent',
    hoverColor: colors.white,
    hoverBackgroundColor: colors.purpleHover,
    hoverBorderColor: 'transparent',
  },
  contained_secondary: {
    color: colors.black,
    borderColor: colors.white,
    backgroundColor: colors.white,
    hoverColor: colors.white,
    hoverBackgroundColor: colors.blackHover,
    hoverBorderColor: 'transparent',
  },
  outlined_secondary: {
    color: colors.white,
    borderColor: colors.white,
    backgroundColor: 'transparent',
    hoverColor: colors.white,
    hoverBackgroundColor: colors.blackHover,
    hoverBorderColor: 'transparent',
  },
  [DEFAULT]: {
    color: colors.white,
    borderColor: colors.white,
    backgroundColor: 'transparent',
    hoverColor: colors.white,
    hoverBackgroundColor: colors.blackHover,
    hoverBorderColor: 'transparent',
  },
};

export const getMapping = (format?: Format, color?: Color) => {
  if (!format || !color) return mapping[DEFAULT];
  return mapping[`${format}_${color}`] || mapping[DEFAULT];
};

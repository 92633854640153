import React from 'react';
import { Metafields, Product } from '../../../types/ecommerce.types';
import { withQuery } from '../data/withQuery';
import { getCornerImage, getCurrentVariant, getMetafieldV2 } from '../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { resetGalleryItem } from '../../../store/product/productSlice';
import { setShouldCropToAll } from '../../../store/upload/uploadSlice';
import { navigate } from '@reach/router';
import { ProductOptionsTitle } from './ProductOptions.styles';
import styled from 'styled-components';
import { colors } from '@/themes/colorsMapping';
import { getSortDictionary } from './getSortDictionary';

type Variant = {
  title: string;
  link?: string;
  image?: string;
};

const MatWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 0;
  @media (min-width: 800px) {
    margin-bottom: 30px;
  }

  ul {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 12px;
    margin-left: 0;

    padding: 3px;
    margin-top: 13px;
    position: relative;
    list-style-type: none;
    flex-wrap: wrap;
  }

  li {
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid ${colors.darkGray};
    &.active {
      border-color: ${colors.purple};
      box-shadow: 0 0 0 3px rgba(51, 0, 204, 0.2);
    }

    label {
      padding: 0 !important;
      padding-left: 10px !important;
      padding-right: 10px !important;
      padding-bottom: 7px !important;
      margin: 0;
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      @media (max-width: 800px) {
        display: block;
        margin-top: -10px;
      }
    }
  }

  img {
    width: 170%;
    transform: translate(-20%, 0);
    height: 70px;
    object-position: bottom;
    object-fit: cover;
  }
`;

const MatImageOptions: React.FC<{ product: Product; shopMetafields: Metafields }> = ({
  product,
  shopMetafields,
}) => {
  const dispatch = useDispatch();
  const tile = useSelector((state: RootState) => state.upload.tiles[0]);

  if (!product) return null;
  const variants = product?.mat_variants?.references?.edges.map((edge: any) => edge?.node);
  const names: Variant[] = variants?.map((name: any) => ({
    title: name?.mat_type?.value,
    link: name?.handle,
    image: getCornerImage(name.images.edges),
  }));

  const currentProduct = getMetafieldV2('mat_type', product.metafields);
  const currentVariantFromPageProduct = getCurrentVariant(product?.variants?.edges, null, {
    checkParams: true,
  });
  const frameSize = currentVariantFromPageProduct?.selectedOptions[0]?.value;

  if (currentProduct && names) {
    names.push({
      title: currentProduct,
      image: getCornerImage(product?.images?.edges),
    });
  }

  if (!names || !names.length) return null;

  const sort = getSortDictionary(getMetafieldV2('mat_toggle_order', shopMetafields));
  names.sort((a, b) => sort[a.title.toLowerCase()] - sort[b.title.toLowerCase()]);

  return (
    <MatWrapper>
      <ProductOptionsTitle>Mat: </ProductOptionsTitle>
      <ul>
        {names.map((name, index) => (
          <li
            className={!name.link ? 'active' : ''}
            key={index}
            onClick={() => {
              if (!name.link) return;
              dispatch(resetGalleryItem());
              if (tile) {
                dispatch(setShouldCropToAll());
              }
              const url = `/products/${name.link}?size=${frameSize}`;
              window.scrollTo({ top: 0, behavior: 'smooth' });
              setTimeout(() => {
                navigate(url);
              }, 250);
            }}
          >
            {!name.link && (
              <input
                type="radio"
                checked={true}
                onChange={() => {}}
                className="fn-variant-input tmplt-product__variants-input visually-hidden"
              />
            )}
            <img src={name.image} alt="" />
            <label>
              <span>{name.title}</span>
            </label>
          </li>
        ))}
      </ul>
    </MatWrapper>
  );
};
MatImageOptions.displayName = 'MatImageOptions';
export default withQuery(MatImageOptions);

import { Metafields } from '@/types/ecommerce.types';
import { getMetafieldV2 } from '@/utils/utils';
import { useMediaQuery } from 'react-responsive';

export const useModelPosition = (metafields?: Metafields) => {
  const isMobile = useMediaQuery({ maxWidth: 799 });
  const modelPosition = getMetafieldV2('3d_model_position', metafields) ?? '';
  const mobileModelPosition = getMetafieldV2('3d_model_mobile_position', metafields) ?? '';

  return isMobile ? mobileModelPosition ?? modelPosition : modelPosition;
};

import React from 'react';
import {
  AccordionContainer,
  AccordionHeader,
  AccordionContent,
  ArrowIcon,
} from './Accordion.styles';
import AccordionArrowIcon from '../icons/AccordionArrowIcon';

interface AccordionProps {
  title: string | React.ReactNode;
  children?: React.ReactNode | React.ComponentType;
  onClick?: () => void;
  toggle?: boolean;
}

const Accordion = ({ title, children, onClick, toggle = true }: AccordionProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const toggleAccordion = () => {
    setIsExpanded((expanded) => !expanded);
  };

  const renderContent = () => {
    if (React.isValidElement(children)) {
      return children;
    } else if (typeof children === 'function') {
      const ContentComponent = children as React.ComponentType;
      return <ContentComponent />;
    }
    return children;
  };

  return (
    <AccordionContainer>
      <AccordionHeader onClick={toggle ? toggleAccordion : onClick}>
        {title}
        {children ? (
          <ArrowIcon isExpanded={isExpanded}>
            <AccordionArrowIcon />
          </ArrowIcon>
        ) : null}
      </AccordionHeader>
      <AccordionContent isExpanded={isExpanded}>{renderContent()}</AccordionContent>
    </AccordionContainer>
  );
};

export default Accordion;

import React, { useState } from 'react';
import styled from 'styled-components';
import { scrollToReviews } from '@/utils/utils';
import Stars from '@/components/templates/icons/StarsIcon';
import useFetchReviews from '@/hooks/useFetchReviews';
import { colors } from '@/themes/colorsMapping';
import Accordion from '../../templates/accordion/Accordion';

type SizeTypes = {
  size?: 'normal' | 'small';
};

type PropTypes = {
  className?: string;
  size?: SizeTypes['size'];
};

const StartsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 5px 2px 10px;
`;

const ReviewWrapper = styled.div<SizeTypes>`
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

const StarsComponent = (size) => (
  <StartsWrapper className={'stars-wrapper'}>
    <Stars color={colors.blackText} />
  </StartsWrapper>
);

const ProductReviewMock2 = ({ className, size = 'normal' }: PropTypes) => {
  const [count, setCount] = useState<null | number>(null);
  useFetchReviews(setCount);

  return (
    <Accordion
      toggle={false}
      title={
        <ReviewWrapper>
          <span>Reviews {count ? `(${count})` : null}</span> <StarsComponent />
        </ReviewWrapper>
      }
      onClick={scrollToReviews}
    />
  );
};
export default ProductReviewMock2;

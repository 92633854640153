const SearchIcon = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.49967 15.8333C13.1816 15.8333 16.1663 12.8486 16.1663 9.16667C16.1663 5.48477 13.1816 2.5 9.49967 2.5C5.81778 2.5 2.83301 5.48477 2.83301 9.16667C2.83301 12.8486 5.81778 15.8333 9.49967 15.8333Z"
      stroke="#262626"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.833 18.5L14.208 13.875"
      stroke="#262626"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SearchIcon;

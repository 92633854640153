import React from 'react';
import styled from 'styled-components';
import { colors } from '@/themes/colorsMapping';

interface SectionTitleProps {
  title: string;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
  align?: 'left' | 'center' | 'right';
  style?: React.CSSProperties;
}

const StyledTitle = styled.h3<{ align: 'left' | 'center' | 'right' }>`
  font-family: 'Termina';
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: ${colors.blackText};
  margin-bottom: 25px;
  text-align: ${(props) => props.align};

  @media screen and (min-width: 768px) {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 40px;
  }
`;

const SectionTitle = ({ title, as, style, align = 'left' }: SectionTitleProps) => {
  return (
    <StyledTitle as={as} align={align} style={style}>
      {title}
    </StyledTitle>
  );
};

export default SectionTitle;

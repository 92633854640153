export enum SectionTypes {
  SIZE = 'Size',
  STYLE = 'Style',
  COLOR = 'Color',
  MAT = 'Mat',
}

export enum PageType {
  LP = 'LP',
  BASE = 'BASE',
  BASEV2 = 'BASEV2',
}

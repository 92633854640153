import { Metaobject, MetaobjectEdge } from '@shopify/hydrogen-react/storefront-api-types';
import React from 'react';
import styled from 'styled-components';

interface ProductBadgeProps {
  productTags: string[];
  shopMetaobjects: MetaobjectEdge[];
}

const BadgeContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  transform: translate(-50%, -50%);

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const ProductBadge: React.FC<ProductBadgeProps> = ({ productTags, shopMetaobjects }) => {
  const badgeListEdges = shopMetaobjects || [];

  return (
    <BadgeContainer className="product-badges">
      {badgeListEdges.map((edge: { node: Metaobject }) => {
        const badgeList = edge.node.fields.find((field) => field.key === 'product_badge_list');

        if (!badgeList?.references?.edges) return null;

        return badgeList.references.edges.map(({ node }) => {
          const filterTag = node.fields.find((field) => field.key === 'badge_filter_tag')?.value;
          const imageAlt = node.fields.find((field) => field.key === 'badge_title_alt')?.value;
          const badgeImage = node.fields.find((field) => field.key === 'badge_svg')?.reference
            ?.image;
          const badgeUrl = badgeImage?.url;
          if (filterTag && badgeUrl && productTags.includes(filterTag)) {
            return <img key={node.id} src={badgeUrl} alt={imageAlt} />;
          }
          return null;
        });
      })}
    </BadgeContainer>
  );
};

export default ProductBadge;

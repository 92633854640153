import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Product } from '../../../types/ecommerce.types';
import { RootState } from '../../../store';
import { withQuery } from '../data/withQuery';
import { getMetafieldV2 } from '../../../utils/utils';
import FetchGalleryItemDescription from '../viewer/gallery/FetchGalleryItemDescription';
import * as S from './ProductDescription.styles';

const GalleryItemsDescription: React.FC<{ product: Product }> = ({ product }) => {
  const { galleryItemTitles } = useSelector((state: RootState) => state.product);
  const [selectedTab, setSelectedTab] = useState(0);

  const products = getMetafieldV2('tile', product?.metafields);
  const productsParsed: string[] = products ? JSON.parse(products) : [];
  const productsHandle = productsParsed?.map((item) => item.split(':')[0]);
  const productsHandleUnique = Array.from(new Set(productsHandle));

  const Tabs = () => (
    <div data-cy="gallery-items-description">
      <div>
        <S.GalleryTabs>
          {productsHandleUnique.map((handle, index) => (
            <S.GalleryTab
              selected={index === selectedTab}
              onClick={() => {
                setSelectedTab(index);
              }}
              key={handle}
            >
              {galleryItemTitles[handle]}
            </S.GalleryTab>
          ))}
        </S.GalleryTabs>
      </div>
    </div>
  );

  if (!product?.descriptionHtml) {
    return null;
  }

  return (
    <>
      <S.GalleryItemWrapper>
        {productsHandleUnique.map((handle, index) => (
          <div key={handle} style={{ display: index === selectedTab ? 'block' : 'none' }}>
            <FetchGalleryItemDescription key={handle} handle={handle} tabs={<Tabs />} />
          </div>
        ))}
      </S.GalleryItemWrapper>
    </>
  );
};
GalleryItemsDescription.displayName = 'GalleryItemsDescription';
export default withQuery(GalleryItemsDescription);

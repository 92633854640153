import React, { useEffect, useRef } from 'react';
import reviewSettings from './reviewsSettings';
import * as S from './Reviews.styles';
import Container from '../shared/Container';
import SectionTitle from '../shared/SectionTitle';

const Reviews: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (window.ReviewsWidget && typeof window.ReviewsWidget === 'function' && ref && ref.current) {
      new window.ReviewsWidget('#ReviewsWidget', reviewSettings);
    } else {
    }
  }, [ref, window.ReviewsWidget]);

  return (
    <S.Wrapper>
      <Container>
        <div className="tmplt-product__reviews">
          <SectionTitle title="Reviews" />
          <div ref={ref} id="ReviewsWidget" className="ReviewsWidgetClone" />
        </div>
      </Container>
    </S.Wrapper>
  );
};
Reviews.displayName = 'Reviews';
export default Reviews;

import styled from 'styled-components';

const colors: { [key: string]: string } = {
  Bone: '#CECCB5',
  Timberwolf: '#E3E0D8',
  'Light Gray': '#F1F0EC',
};

export const Wrapper = styled.section<{ isGallery: boolean }>`
  p + p {
    margin: 15px 0;
  }
  ul {
    list-style-type: initial;
    margin-left: 30px;
    margin-bottom: 30px;
  }
  img {
    object-fit: contain;
    border-radius: 7px;
    max-width: 100%;
  }
`;

export const GalleryItemWrapper = styled.section``;

export const GalleryTabs = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 18px;
  flex-wrap: wrap;
  margin-bottom: 15px;
  @media (min-width: 800px) {
    padding: 15px 0;
    margin-bottom: 0;
  }
`;
export const GalleryTab = styled.div<{ selected: boolean }>`
  margin-right: 20px;
  cursor: pointer;
  padding: 2px 0;
  border-bottom: 3px solid ${(props) => (props.selected ? '#3300CC' : 'transparent')};
  &:hover {
    color: ${(props) => (props.selected ? 'inherit' : '#3300CC')};
  }
`;

export const GalleryWrapper = styled.section`
  > div {
    overflow: visible;
    text-align: left;
  }
`;

export const GalleryDescription = styled.div`
  margin: 0 0 0;
  flex: 1;
  @media (min-width: 800px) {
    margin: 0;
    /* margin-bottom: 60px; */
  }
  p {
    margin: 20px 0;
  }
  ul {
    margin-left: 25px !important;
  }
  > div {
    @media (min-width: 800px) {
      display: flex;
      column-gap: 100px;
    }
    > div {
      flex: 1;
      img {
        width: 100%;
      }
      > div > ul {
        margin-top: 20px;
        margin-left: 20px;
      }
      &:last-child {
        margin-top: 0 !important;
      }
    }
    p.typo__header {
      margin: 0 0 20px;
      max-width: 200px;
      text-align: left;

      @media (min-width: 800px) {
        max-width: 400px;
        margin: 0 auto 20px;
        text-align: center;

        .editorial-content & {
          text-align: left;
          margin: 0 0 20px;
        }
      }
    }
  }
`;

export const Description = styled.div<{ isGallery: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isGallery ? 'column-reverse' : 'column')};
  width: 100%;
  padding-top: ${(props) => (props.isGallery ? '0' : '40px')};
  @media (min-width: 800px) {
    /* flex-direction: row; */
    flex-direction: ${(props) => (props.isGallery ? 'row-reverse' : 'row')};
    gap: ${(props) => (props.isGallery ? '0' : '50px')};
    padding-top: 0;
    > div {
      flex: 1;
    }
    > div:first-child > div {
      padding-left: ${(props) => (props.isGallery ? '120px' : '0')};
    }
  }

  > div,
  > img {
    position: relative;
    display: flex;
  }
  > div:last-child {
    margin-top: 30px;
    display: ${(props) => (!props.isGallery ? 'block' : 'none')};
    &:before {
      display: none;
      @media (min-width: 800px) {
        margin-left: 0;
        width: 100%;
        background-color: ${(props) => (props.color ? colors[props.color] : colors['Bone'])};
        height: 80%;
        position: absolute;
        z-index: 0;
        content: '';
        top: 0;
        left: 0;
        display: block;
      }
    }

    &.tmplt-product-corner-v2 {
      &:before {
        @media (min-width: 800px) {
          display: none;
        }
      }
    }

    @media (min-width: 800px) {
      margin-top: 0px;
      display: block;
    }
  }
  img {
    width: calc(100% + 40px);
    margin-left: -20px;
    position: relative;
    @media (min-width: 800px) {
      width: 100%;
      margin-left: 0;
    }
  }
  .typo__header {
    margin-bottom: 30px;
    @media (max-width: 800px) {
      max-width: 200px;
    }
  }
  > div > div > div > ul {
    margin-top: 20px;
    margin-left: 20px;
  }
  > div > div > div > p {
    margin-top: 20px;
    @media (min-width: 800px) {
      margin-top: 30px;
    }
  }
  > div > div {
    margin-bottom: 20px;
    max-width: ${(props) => (props.isGallery ? 'auto' : '450px')};
  }
`;

export const Video = styled.div`
  background-color: #fff;
  width: calc(100% + 40px);
  text-align: center;
  display: flex;
  margin-left: -20px;
  justify-content: center;
  align-items: center;
  @media (min-width: 800px) {
    width: 100%;
    margin-left: 0;
    margin-bottom: 10px;
  }
  video {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }
`;

export const GalleryVideo = styled.div`
  background-color: #fff;
  width: 100%;
  text-align: center;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;

  video {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }
`;

export const FinePrint = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 50px;
  flex-direction: column-reverse;

  &:last-child {
    margin-bottom: 0;
  }

  > div:first-child {
    flex: 4;
  }
  > div:last-child {
    flex: 3;
  }
  img {
    width: 100%;
  }
  .typo__header {
    margin: 20px 0 30px;
    max-width: 200px;
    @media (min-width: 800px) {
      margin: 0 0 30px;
      max-width: 250px;
    }
  }
  p {
    margin: 20px 0;
  }

  .title {
    font-weight: 600;
  }
`;

export const ProductTypeVideo = styled.div`
  background-color: #fff;
  width: 100%;
  text-align: center;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;

  video {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }
`;

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useScript from '@/hooks/useScript';
import useSetupPartner from '@/hooks/useSetupPartner';
import useSetupArtisticUrls from '@/hooks/useSetupArtisticUrls';

import * as S from './ProductBaseV2.styles';

import { ProductTypes, RouteProps } from '@/types/ecommerce.types';

import { RootState } from '@/store';
import { setShouldCrop } from '@/store/editor/editorSlice';
import { setTiles } from '@/store/upload/uploadSlice';
import DataService from '@/services/DataService';
import TrackingService from '@/services/TrackingService';
import {
  getCurrentVariant,
  getFrameSizeFromVariant,
  getMetafieldV2,
  getQueryParams,
} from '@/utils/utils';

import { getArtisticHandle } from '../shared/artistic/getArtisticHandle';
import { removeArtisticTiles } from '../shared/artistic/removeArtisticTiles';

import ProductVariants from '../options/ProductVariants';
import ProductSwatches from '../options/ProductSwatches';
import Cart from '../cart/Cart';
import ProductCta from '../cart/ProductCta';
import Reviews from '../reviews/Reviews';
import Resolution from '../dialog/Resolution';
import ValueProps from '../valueProps/ValueProps';
import ProductOptions from '../options/MatOptions';
import ProductHallwaySize from '../options/ProductHallwaySize';
import RelatedProducts from '../relatedProducts/RelatedProducts';
import Performance from '../upload/Performance';
import GalleryDescription from '../description/GalleryDescription';
import GalleryItemsDescription from '../description/GalleryItemsDescription';
import Accordion from '../../templates/accordion/Accordion';
import AccordionContainer from '../../templates/accordion/AccordionContainer';
import ProductMedia from '../media/ProductMedia';
import { useMediaQuery } from 'react-responsive';
import ProductFaqV2 from '../faq/ProductFaqV2';
import { BuilderWrapper } from '../productLP/ProductLP.styles';
import Selector from '../options/Selector';
import ProductCollections from '../options/ProductCollections';
import FloatingCta from '../cart/FloatingCta';
import { SectionTypes } from '../types';
import ProductDescriptionV2 from '../description/ProductDescriptionV2';
import ProductHeaderV2 from '../header/ProductHeaderV2';
import ProductReviewMock2 from '../shared/ProductReviewMock2';
import ProductInfoV2 from '../header/ProductInfoV2';
import OrientationOptions from '../options/OrientationOptions';
import ProductAttributionDescriptionV2 from '../description/ProductAttributionDescriptionV2';
import { FrameBuilderStyled } from './ProductBaseV2.styles';
import ProductImageSwatches from '../options/ProductImageSwatches';
import MatImageOptions from '../options/MatImageOptions';

const ProductBaseV2 = ({ product, handle, shopMetafields, colorCollection }: RouteProps) => {
  // Load reviews.io scripts
  useScript('https://widget.reviews.io/polaris/build.js');
  useSetupPartner();
  useSetupArtisticUrls(product);

  const [shoudReInit, setShouldReInit] = useState(false);
  const { partner, type } = useSelector((state: RootState) => state.product);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 799 });
  const [actionTab, setActionTab] = useState<SectionTypes | null>(
    getQueryParams('style') === 'open' ? SectionTypes.STYLE : null
  );
  const uploadRef = useRef<HTMLInputElement>(null!);
  const mount = getMetafieldV2('plp_use_type', product?.metafields);

  const { tiles } = useSelector((state: RootState) => state.upload);
  const artisticHandle = getArtisticHandle();
  const currentVariant = getCurrentVariant(product?.variants?.edges);
  const frameSizeInPixels = getFrameSizeFromVariant(currentVariant);

  const isGallery = type === ProductTypes.GALLERY;

  const navHeader = document.getElementById('fn-main-header');
  const mainContent = document.getElementById('MainContent');

  navHeader?.classList.toggle('gallery-header', isGallery);

  if (mainContent) {
    mainContent.dataset.prodtype = String(type);
    mainContent.dataset.handle = String(handle);
  }

  const onUploadPhoto = (e: React.MouseEvent) => {
    e.preventDefault();
    uploadRef.current?.click();
  };

  const onCloseSection = () => setActionTab(null);
  const pageName = '';

  const getProductSelector = (
    <>
      {isMobile && (
        <>
          <Selector
            onClose={onCloseSection}
            title={'Choose style'}
            open={actionTab === SectionTypes.STYLE}
          >
            <>
              <ProductCollections product={product} />
              <ProductSwatches pagePath={`/pages/${pageName}`} showDefaultColor />
            </>
          </Selector>
          <Selector
            onClose={onCloseSection}
            title={'Choose color'}
            open={actionTab === SectionTypes.COLOR}
          >
            <>
              <ProductImageSwatches pagePath={`/products${pageName}`} showDefaultColor />
            </>
          </Selector>
          <Selector
            onClose={onCloseSection}
            title={'Choose size'}
            open={actionTab === SectionTypes.SIZE}
          >
            <ProductVariants showMeta={false} showPrice />
            <ProductHallwaySize />
          </Selector>
          <Selector
            onClose={onCloseSection}
            title={'Choose mat'}
            open={actionTab === SectionTypes.MAT}
          >
            <MatImageOptions />
          </Selector>

          <Cart>
            <FloatingCta product={product} fixed onUploadPhoto={onUploadPhoto} />
          </Cart>
        </>
      )}
      {!isMobile && (
        <div className="tmplt-product__content-wrap" style={{ zIndex: 6 }}>
          <ProductHeaderV2 />
          <ProductInfoV2 />
          <Cart>
            <S.OptionsWrapper className="tmplt-product__options-wrapper">
              <S.OptionsBlock className="fn-product-options tmplt-product__options tmplt-product__options--opened">
                <ProductSwatches sectionTitle={'Color'} />
                <ProductVariants sectionTitle={'Size'} />
                <ProductHallwaySize />
              </S.OptionsBlock>
              <ProductOptions />
              <OrientationOptions />
            </S.OptionsWrapper>
            <ProductCta showFloatingCta={false} />
            <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
              <ValueProps />
              <AccordionContainer>
                {isGallery && (
                  <Accordion title="About this set">
                    <GalleryDescription />
                  </Accordion>
                )}
                {isGallery && (
                  <Accordion title="Products featured">
                    <GalleryItemsDescription />
                  </Accordion>
                )}
                {!isGallery && (
                  <Accordion title="Product details">
                    <ProductDescriptionV2 />
                  </Accordion>
                )}
                <Accordion title="FAQ">
                  <ProductFaqV2 />
                </Accordion>
                <ProductReviewMock2 />
              </AccordionContainer>
            </div>
          </Cart>
        </div>
      )}
    </>
  );

  useEffect(() => {
    DataService.saveTiles(tiles);
    setShouldReInit(!shoudReInit);
    setTimeout(() => {
      setShouldReInit(!shoudReInit);
    }, 10);
  }, [tiles]);

  useEffect(() => {
    if (handle || artisticHandle) {
      const tilesWithoutArtistic = removeArtisticTiles(tiles);
      dispatch(setTiles(tilesWithoutArtistic));
      //window.scrollTo({ top: 0, behavior: "smooth" });
      dispatch(setShouldCrop(true));
    }
  }, [handle, artisticHandle]);

  // Track a single view_item event whenever the productVariant changes
  useEffect(() => {
    if (product) {
      TrackingService.ga4Track('view_item', product);
    }
  }, [product, currentVariant]);

  useEffect(() => {
    const pageTitle =
      product?.seo?.title?.toString() || 'Frameology: Print and Frame Photos Online';
    document.title = pageTitle;
  }, [product]);

  return (
    <>
      <Resolution />
      <S.ProducWrapper isGallery={isGallery}>
        <BuilderWrapper className="tmplt-product">
          <S.MediaWrapper
            className="tmplt-product__media-wrapper"
            data-prodtype={type}
            data-handle={handle}
            isGallery={isGallery}
          >
            <FrameBuilderStyled
              product={product}
              shopMetafields={shopMetafields}
              frameSizes={frameSizeInPixels}
              variant={currentVariant}
              setActionTab={setActionTab}
              textARButton={isMobile ? 'AR' : 'View in your room'}
              colorCollection={colorCollection}
            />
          </S.MediaWrapper>
          {getProductSelector}
        </BuilderWrapper>
      </S.ProducWrapper>
      {isGallery && isMobile ? (
        <>
          <ProductHeaderV2 />
          <ProductInfoV2 />
        </>
      ) : null}
      {isMobile ? (
        <>
          {isGallery ? (
            <S.GalleryColorSwatches>
              <ProductSwatches />
            </S.GalleryColorSwatches>
          ) : null}
          <ValueProps />
          <AccordionContainer>
            {isGallery && (
              <>
                <Accordion title="About this set">
                  <GalleryDescription />
                </Accordion>
                <Accordion title="Products featured">
                  <GalleryItemsDescription />
                </Accordion>
              </>
            )}
            {!isGallery && (
              <Accordion title="Product details">
                <ProductDescriptionV2 />
              </Accordion>
            )}
            <Accordion title="FAQ">
              <ProductFaqV2 />
            </Accordion>
            <ProductReviewMock2 />
          </AccordionContainer>
        </>
      ) : null}
      <ProductAttributionDescriptionV2 />
      <ProductMedia />
      {!isGallery && <RelatedProducts />}
      <Reviews />
      <Performance product={product} />
    </>
  );
};
export default ProductBaseV2;

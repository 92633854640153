import React from 'react';
import styled from 'styled-components';

interface ContainerWrapperProps {
  size?: 'medium' | 'default';
}

const ContainerWrapper = styled.div<ContainerWrapperProps>`
  width: 100%;
  max-width: ${(props) => (props.size === 'medium' ? '1100px' : '1200px')};
  margin: 0 auto;
  padding: 0 16px;

  @media (min-width: 768px) {
    padding: 0 24px;
  }

  @media (min-width: 1140px) {
    padding: 0;
  }
`;

interface ContainerProps {
  children: React.ReactNode;
  size?: 'medium' | 'default';
}

const Container: React.FC<ContainerProps> = ({ children, size = 'default' }) => {
  return <ContainerWrapper size={size}>{children}</ContainerWrapper>;
};

export default Container;

import React from 'react';
import { Metafields, Product } from '../../../types/ecommerce.types';
import { withQuery } from '../data/withQuery';
import { getCurrentVariant, getMetafieldV2 } from '../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { resetGalleryItem } from '../../../store/product/productSlice';
import { setShouldCropToAll } from '../../../store/upload/uploadSlice';
import { navigate } from '@reach/router';
import { MatWrapper, ProductOptionsTitle } from './ProductOptions.styles';
import { getSortDictionary } from './getSortDictionary';

type Variant = {
  title: string;
  link?: string;
};

const MatOptions: React.FC<{ product: Product; shopMetafields: Metafields }> = ({
  product,
  shopMetafields,
}) => {
  const dispatch = useDispatch();
  const tile = useSelector((state: RootState) => state.upload.tiles[0]);

  if (!product) return null;
  const variants = product?.mat_variants?.references?.edges.map((edge: any) => edge?.node);
  const names: Variant[] = variants?.map((name: any) => ({
    title: name?.mat_type?.value,
    link: name?.handle,
  }));

  const currentProduct = getMetafieldV2('mat_type', product.metafields);
  const currentVariantFromPageProduct = getCurrentVariant(product?.variants?.edges, null, {
    checkParams: true,
  });
  const frameSize = currentVariantFromPageProduct?.selectedOptions[0]?.value;

  if (currentProduct && names) {
    names.push({
      title: currentProduct,
    });
  }

  if (!names || !names.length) return null;

  const sort = getSortDictionary(getMetafieldV2('mat_toggle_order', shopMetafields));
  names.sort((a, b) => sort[a.title.toLowerCase()] - sort[b.title.toLowerCase()]);
  const currentSelecion = names.find((item) => !item.link)?.title;

  return (
    <MatWrapper>
      <ProductOptionsTitle>
        Mat: <span>{currentSelecion}</span>
      </ProductOptionsTitle>
      <ul className="tmplt-product__variants-list">
        {names.map((name, index) => (
          <li
            className="typo__paragraph--small tmplt-product__variants-item tmplt-product__variants-item--"
            key={index}
            onClick={() => {
              if (!name.link) return;
              dispatch(resetGalleryItem());
              if (tile) {
                dispatch(setShouldCropToAll());
              }
              const url = `/products/${name.link}?size=${frameSize}`;
              window.scrollTo({ top: 0, behavior: 'smooth' });
              setTimeout(() => {
                navigate(url);
              }, 250);
            }}
          >
            {!name.link && (
              <input
                type="radio"
                checked={true}
                onChange={() => {}}
                className="fn-variant-input tmplt-product__variants-input visually-hidden"
              />
            )}
            <label className="tmplt-product__variants-label">
              <span>{name.title}</span>
            </label>
          </li>
        ))}
      </ul>
    </MatWrapper>
  );
};
MatOptions.displayName = 'MatOptions';
export default withQuery(MatOptions);

import { colors } from '@/themes/colorsMapping';
import React from 'react';
import styled from 'styled-components';

const StyledAccordionContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${colors.mediumGray};
  margin: 20px;
  @media (min-width: 800px) {
    margin: 20px 0 0 0;
    border-top: 0;
    border-bottom: 1px solid ${colors.mediumGray};
  }

  > div + div {
    border-top: 1px solid ${colors.mediumGray};
  }
`;

interface AccordionContainerProps {
  children: React.ReactNode;
}

const AccordionContainer = ({ children }: AccordionContainerProps) => (
  <StyledAccordionContainer>{children}</StyledAccordionContainer>
);

export default AccordionContainer;

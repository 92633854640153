import html2canvas from 'html2canvas';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Metafields, Product } from '../../../types/ecommerce.types';
import { RootState } from '../../../store';
import { setCartFeedbackMessage, setCartState } from '../../../store/cart/cartSlice';
import { withQuery } from '../data/withQuery';
import ImageService from '../../../services/ImageService';
import {
  getCurrentVariant,
  getMetafield,
  getMetafieldV2,
  handleizeProductType,
  getPublicIdFromCloudinaryUrl,
  parseGid,
} from '../../../utils/utils';
import cloudinaryInstance from '../../../api/cloudinary';
import { scale } from '@cloudinary/url-gen/actions/resize';
import CartModal from './CartModal';
import { resetTiles, setValidation } from '../../../store/upload/uploadSlice';
import { updateCartAttributes } from './cartUtils';
import ModalEmptyPhoto from '../dialog/ModalEmptyPhoto';
import { ProductTypes } from '../../../types/ecommerce.types';
import { closeGalleryUnits, setOrientation } from '../../../store/viewer/viewerSlice';
import getProductDescription from '../shared/getProductDescription';
import { quickShipDisplay, debug } from '../../../utils/utils';
import TrackingService from '../../../services/TrackingService';
import { ModelViewerElement } from '@google/model-viewer';
import withDefaultModelPosition from '../../../utils/withDefaultModelPosition';
import { s3URL, uploadImageToS3 } from '@/services/s3Upload';

export interface TileObj {
  x: number;
  y: number;
  width: number;
  height: number;
}

const preloadImage = (src?: string) => {
  if (!src) return;
  const img = new Image();
  img.src = src;
};

const Cart: React.FC<{
  product: Product;
  shopMetafields: Metafields;
}> = ({ product, children, shopMetafields }) => {
  const dispatch = useDispatch();
  const [showPreview, setShowPreview] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [ignoreImage, setIgnoreImage] = useState(false);
  const { selectedSkin } = useSelector((state: RootState) => state.editor);
  const { type, items } = useSelector((state: RootState) => state.product);
  const { cartFeedbackMessage } = useSelector((state: RootState) => state.cart);
  const newPosition = String(0);
  const { tiles } = useSelector((state: RootState) => state.upload);
  const tile = useSelector((state: RootState) => state.upload.tiles[newPosition]);
  const metafields = product?.metafields;
  const isGallery = type === ProductTypes.GALLERY;
  const productTitle = getMetafield('group_product_name', metafields);
  const urlParams = new URLSearchParams(window.location.search);
  const updateParam = urlParams.get('update');
  const currentVariant = getCurrentVariant(product?.variants?.edges);
  const comparePrice = currentVariant?.compareAtPrice?.amount;
  const variantId = currentVariant ? parseGid(currentVariant.id) : null;
  const tilesAdded = items?.filter((item: any, index: number) => tiles[index]).length;
  const imageRequired =
    getMetafieldV2('image_required_for_purchase', product?.metafields) === 'true';
  const quickShip = quickShipDisplay(product?.tags, shopMetafields);
  const quickShipLabel = getMetafieldV2('quick_ship_label', shopMetafields);
  const arBuilderEnabled = getMetafieldV2('3d_builder_enabled', product?.metafields) === 'true';
  const {
    item_list_id,
    item_list_name,
    index: item_list_index,
    item_list_preview_image_id,
  } = TrackingService.getListAttribution();
  const lineItemDescription = Object.values(getProductDescription(product, shopMetafields)).join(
    ' • '
  );

  const isLowResImg = tiles
    ? Object.values(tiles ?? {})
        ?.map((item) => item?.validation)
        ?.some((item) => item?.qualityIsOk === false)
    : false;
  const lowResWarningLabel = getMetafieldV2('low_res_warning_label', shopMetafields);

  // Add new line item properties here IF AND ONLY IF
  //   they are not required for fulfillment of gallery subproducts.
  const allContextLineItemProperties = (properties = {}) => {
    return (() => ({
      ...properties,
      ...(lineItemDescription && { _description: lineItemDescription }),
      ...(quickShip && quickShipLabel && { _quickShip: quickShipLabel }),
      ...(arBuilderEnabled && { _arModel: true }),
      ...(isLowResImg && lowResWarningLabel && { _isLowResImage: lowResWarningLabel }),
      ...(comparePrice && { _comparePrice: comparePrice }),
      ...(item_list_id && { _itemListId: item_list_id }),
      ...(item_list_name && { _itemListName: item_list_name }),
      ...(item_list_index && { _itemListIndex: item_list_index }),
      ...(item_list_preview_image_id && { _itemListPreviewImageId: item_list_preview_image_id }),
      ...(isGallery && currentVariant && { _gallerySku: currentVariant.sku }),
    }))();
  };

  const setupLineItems = (
    remainingImagesToUpload: any,
    frameElements: any,
    frameItems: any,
    galleryItems: any,
    callback: any
  ) => {
    if (remainingImagesToUpload <= 0) {
      // now fix line item options
      let lineItemOptions: any = {};

      if (frameElements.length === 1) {
        lineItemOptions = {
          ...lineItemOptions,
          ...frameItems[0],
        };
        delete lineItemOptions._index;
      } else {
        // The _gallery lineItemProperty contains all of the details for the subproducts
        //   that make up the gallery
        lineItemOptions._gallery = JSON.stringify(galleryItems);
        lineItemOptions._thumbnail = '';
        lineItemOptions._printerAsset = '';
      }

      lineItemOptions = allContextLineItemProperties(lineItemOptions);

      // This creates the line item properties for the actual gallery lineItem
      //   as opposed to the properties created in thunkCreateLineItems which creates
      //   the lineItem properties for the individual subproducts of the gallery

      const galleryEl = document.getElementById('viewer-wrapper');
      if (isGallery && galleryEl) {
        html2canvas(galleryEl, {
          scale: 2, // we need bigger printPreview images for gallery
          useCORS: true,
          scrollX: 0,
          scrollY: 0,
          ignoreElements: (element) =>
            element.getAttribute('data-hide') === 'true' || element.tagName === 'MODEL-VIEWER',
        }).then(function (canvas) {
          const dataUrl = canvas.toDataURL('image/jpeg');
          canvas.toBlob(async (blob) => {
            await uploadImageToS3(`${tile?.publicId}-preview`, blob);
            //  preloadImage(image);
          }, 'image/jpeg');

          ImageService.upload(dataUrl, {
            tags: [tile?.publicId, 'preview_file'],
            context: { publicId: tile?.publicId, type: 'preview_file' },
          }).then(async (data: any) => {
            const printPreview = data.data;
            const thumbnail = cloudinaryInstance
              .image(printPreview.public_id)
              .resize(scale().width(90));
            const galleryFramesCount = Math.round(
              Number(getMetafieldV2('gallery_item_count', metafields))
            );

            lineItemOptions._thumbnail = thumbnail.toURL();
            lineItemOptions._printPreview = printPreview.url;
            lineItemOptions._printerAsset = '--';
            lineItemOptions._frames = `${galleryFramesCount} frames`;

            if (productTitle) {
              lineItemOptions._productTitle = productTitle;
            }

            callback(lineItemOptions);
          });
        });
      } else {
        callback(lineItemOptions);
      }
    }
  };

  const thunkCreateLineItems = (callback: any) => async (dispatch: any, getState: any) => {
    // debug('[ADD TO CART]>[thunkCreateLineItems]>[START]');
    let frameElements = Array.from(
      document.querySelectorAll<HTMLElement>("[data-ref='frame-image']")
    );
    // Filter out model viewer elements for gallery since we are taking screenshots
    // only for the regular preview and not 3d model
    if (isGallery) {
      frameElements = frameElements.filter((element) => element.tagName !== 'MODEL-VIEWER');
    }

    const totalOfImagesToProcess = frameElements.filter((item, index) =>
      tiles[index] ? true : false
    ).length;
    let remainingImagesToUpload = totalOfImagesToProcess;
    // debug(
    //   '[ADD TO CART]>[thunkCreateLineItems]>totalOfImagesToProcess',
    //   totalOfImagesToProcess
    // );
    const skinImage: any = document.getElementById('skin-image');
    if (skinImage) {
      skinImage.style.objectFit = 'none';
    }
    const frameItems: any = [];
    const galleryItems: any = [];

    frameElements.forEach((item, index) => {
      const interval = setInterval(async function () {
        const firstState = getState();
        const items = firstState.product.items;
        const tiles = firstState.upload.tiles;
        const position = String(index);
        const tile = tiles[position];
        const productItem = items[index];
        // debug('[ADD TO CART]>[thunkCreateLineItems]>[frameElements loop]', [
        //   index,
        //   item,
        //   tile,
        //   productItem,
        //   frameItems,
        // ]);
        // Handle
        if (tile) {
          // Give the customer a visual cue that something is happening while the images upload
          if (tile.uploading) {
            if (totalOfImagesToProcess === 1) {
              dispatch(setCartFeedbackMessage(`Processing your images. Hang tight.`));
            } else {
              dispatch(
                setCartFeedbackMessage(
                  `Processing your images (${
                    frameItems.filter((item: any) => item._printerAsset !== '').length + 1
                  } of ${totalOfImagesToProcess}). Hang tight.`
                )
              );
            }
          } else {
            // debug(
            //   '[ADD TO CART]>[thunkCreateLineItems]>[frameElements loop]> Create thumbnail'
            // );
            clearInterval(interval);
            // dispatch(setCartFeedbackMessage(``));
            let dataUrl;
            let previewBlob;

            if (item.tagName === 'MODEL-VIEWER') {
              const model = item as ModelViewerElement;
              await withDefaultModelPosition(model, async () => {
                previewBlob = await model.toBlob({ idealAspect: true });
                dataUrl = await ImageService.blobToBase64(previewBlob);
              });
            } else {
              try {
                const canvas = await html2canvas(item, {
                  scale: isGallery ? 5 : 1, // we need bigger printPreview images for gallery
                  useCORS: true,
                  scrollX: 0,
                  scrollY: 0,
                  ignoreElements: (element) =>
                    element.getAttribute('data-hide') === 'true' ||
                    element.tagName === 'MODEL-VIEWER',
                });
                await new Promise((resolve) => {
                  canvas.toBlob((blob) => {
                    previewBlob = blob;
                    resolve(undefined);
                  });
                });

                dataUrl = canvas.toDataURL('image/jpeg');
              } catch (error: unknown) {
                console.log('[ADD TO CART] - Could not generate image', error);
              }
            }
            if (!dataUrl) {
              console.log('[ADD TO CART] - Failed to generate preview image');
              return;
            }
            // const dataUrl = canvas.toDataURL('image/jpeg');
            const s3Upload = uploadImageToS3(`${tile?.publicId}-preview`, previewBlob);

            ImageService.upload(dataUrl, {
              tags: [tile?.publicId, 'preview_file'],
              context: { publicId: tile?.publicId, type: 'preview_file' },
            }).then(async (data: any) => {
              await s3Upload;
              const printPreview = data.data;
              const thumbnail = cloudinaryInstance
                .image(printPreview.public_id)
                .resize(scale().width(90));
              // const thumbnail = s3URL(`${tile?.publicId}-preview`);
              let printerAsset = '';
              if (tile && tile.publicId && tile.crop) {
                printerAsset = ImageService.getCroppedImageURL(tile);
              }
              // debug(
              //   '[ADD TO CART]>[thunkCreateLineItems]>[frameElements loop]> created thumbnail and printerAsset',
              //   [thumbnail, printerAsset]
              // );
              const obj: any = {
                _index: index,
                _sku: productItem?.sku,
                _productTitle: productItem?.title ?? productTitle,
                _productType: product.productType,
                _orientation: productItem.orientation,
                _thumbnail: thumbnail.toURL(),
                _printPreview: printPreview.url,
                _printerAsset: printerAsset,
                _crop: JSON.stringify(ImageService.getActualCrop(tile)),
              };
              // This object contains all of the individual details for the photos uploaded to each subproduct of a gallery
              const galleryObj: any = {
                _index: index,
                _sku: productItem?.sku,
                _productType: product.productType,
                _orientation: productItem.orientation,
                _printPreview: printPreview.url.replace(
                  'res.cloudinary.com/frameology/image/upload',
                  '_root_'
                ),
                _printerAsset: printerAsset.replace(
                  'res.cloudinary.com/frameology/image/upload',
                  '_root_'
                ),
                _crop: JSON.stringify(ImageService.getActualCrop(tile)),
              };
              if (selectedSkin) {
                obj._skinTitle = selectedSkin.title;
                obj._skinPublicId = getPublicIdFromCloudinaryUrl(selectedSkin.skin_image);
              }
              frameItems.push(obj);
              galleryItems.push(galleryObj);
              remainingImagesToUpload = remainingImagesToUpload - 1;
              if (remainingImagesToUpload <= 0) {
                setupLineItems(
                  remainingImagesToUpload,
                  frameElements,
                  frameItems,
                  galleryItems,
                  callback
                );
              }
            });
          }
        } else {
          clearInterval(interval);
          const obj: any = {
            _index: index,
            _sku: productItem?.sku,
            _productTitle: productItem?.title,
            _productType: product.productType,
            _orientation: 'portrait',
            _thumbnail: '',
            _printPreview: '',
            _printerAsset: '',
            _crop: '',
          };
          const galleryObj: any = {
            _index: index,
            _sku: productItem?.sku,
            _productType: product.productType,
            _orientation: 'portrait',
            _printPreview: '',
            _printerAsset: '',
            _crop: '',
          };

          frameItems.push(obj);
          galleryItems.push(galleryObj);
          if (frameElements.length === frameItems.length) {
            setupLineItems(
              remainingImagesToUpload,
              frameElements,
              frameItems,
              galleryItems,
              callback
            );
          }
        }
      }, 1000);
    });
  };

  const addToCartShopify = (lineItemOptions: any, startTime: number) => {
    window.cartIsDone = true;
    // debug(
    //   'Executing addToCartShopify function with the following lineItemOptions: ',
    //   lineItemOptions,
    //   'j'
    // );

    // Warn if missing any of the core lineItem properties that are required for fulfillment
    if (
      lineItemOptions._thumbnail === '' ||
      lineItemOptions._printPreview === '' ||
      lineItemOptions._printerAsset === ''
    ) {
      try {
        debug(
          'Warning: missing data for the following lineItemProperties: ',
          Object.entries(lineItemOptions)
            .filter(([key, value]) => value === '')
            .map((prop) => prop[0])
            .join(', ')
        );
      } catch (e) {
        console.log(e);
      }
    }

    // If the `addToCart` function has not been added to the window object by Shopify scripts
    //  then break and throw a warning.
    if (!window.addToCart || !window.changeCart) {
      dispatch(setCartState(null));
      alert('Add to Cart only works when framebuilder is viewed from framelogy.com');
      setShowPreview(false);
    }
    // If window.addToCart exists, then add-to/update Shopify AJAX cart.
    else {
      setShowPreview(false);
      // TODO: Remove the entire block below that supports the `update` URL param. It is not used.
      // If URL contains and `update` start by updating the cart
      if (currentVariant && updateParam) {
        dispatch(setCartState('change'));
        window
          .changeCart(updateParam, 0)
          .then((response: any) => {
            if (response.status === 422) alert(response.description);

            // Recalculate cart items & prices in the DOM.
            window.updateCartContainer(response, updateParam);
            dispatch(setCartState('adding'));

            // Then add item to cart
            window
              .addToCart(variantId, 1, lineItemOptions)
              .then((response: any) => {
                if (response.status === 422) alert(response.description);

                // Update cart attributes
                updateCartAttributes();

                // Recalculate cart items & prices in the DOM
                window.updateCartContainer(response, undefined, 'frame');
              })
              .then(() => {
                const timeElapsedMs = Date.now() - startTime || undefined;
                TrackingService.ga4Track(
                  'add_to_cart',
                  product,
                  // currentVariant,
                  // items?.length ?? 0,
                  1,
                  {
                    klaviyoSendCartUpdate: true,
                    timeElapsedMs: timeElapsedMs,
                  }
                );
              })
              .then(() => {
                // Clear the PDP of previously uploaded images
                dispatch(setCartState(null));
                dispatch(resetTiles());
                // Clear isLowRes flag
                dispatch(setValidation({ isLowRes: false }));
              })
              .catch((error: unknown) => {
                console.error(error);
                dispatch(setCartState('error'));
              });
          })
          .catch((error: unknown) => {
            console.error(error);
            dispatch(setCartState('error'));
          });
      }
      // CORE ADD TO CART WORKFLOW:
      // Add current variant + lineItemOptions to Shopify AJAX cart.
      else if (currentVariant) {
        window
          .addToCart(variantId, 1, lineItemOptions)
          .then((response: any) => {
            if (response.status === 422) alert(response.description);

            // Set cart attributes
            updateCartAttributes();

            // Recalculate cart-related DOM elements with new cart items & prices
            window.updateCartContainer(response, undefined, 'frame');
          })
          .then(() => {
            const timeElapsedMs = Date.now() - startTime || undefined;
            TrackingService.ga4Track(
              'add_to_cart',
              product,
              // currentVariant,
              // items?.length ?? 0,
              1,
              {
                klaviyoSendCartUpdate: true,
                timeElapsedMs: timeElapsedMs,
              }
            );
          })
          .then(() => {
            dispatch(setCartState(null));
            // Clear the PDP of previously uploaded images
            dispatch(resetTiles());
            // Clear isLowRes flag
            dispatch(setValidation({ isLowRes: false }));
            // Reset Framebuilder to portrait orientation
            if (type !== ProductTypes.GALLERY) {
              // Rotate product to portrait
              dispatch(setOrientation('portrait'));
            }
          })
          .catch((error: unknown) => {
            console.error(error);
            // setShowOverlay(false);
            // window.originalFullImage = null;
            dispatch(setCartState('error'));
          });
      }
    }
  };

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (isGallery) {
      // Close measure units from gallery
      dispatch(closeGalleryUnits());
    }
    const startTime = Date.now();

    // debug('onFormSubmit', [
    //   tile,
    //   ignoreImage,
    //   handleizeProductType(product?.productType),
    //   ProductTypes.GALLERY,
    // ]);
    const frameElements = document.querySelectorAll<HTMLElement>("[data-ref='frame-image']");
    const totalOfImagesToProcess = Array.from(frameElements).filter((item, index) =>
      tiles[index] ? true : false
    ).length;
    e.preventDefault();
    if (
      handleizeProductType(product?.productType) === ProductTypes.GALLERY &&
      tilesAdded !== items.length &&
      ignoreImage === false
    ) {
      setOpenModal(true);
      return false;
    }
    if (!tile && !ignoreImage) {
      setOpenModal(true);
    } else {
      setShowPreview(true);
      window.scroll(0, 0);
      dispatch(setCartState('adding'));

      // Handle frame with no photo
      if (!tile && ignoreImage && type !== ProductTypes.GALLERY) {
        dispatch(setCartFeedbackMessage('Adding to cart...'));
        const frameOrientation = items[0]?.orientation;

        let lineItemOptions: any = {
          _orientation: frameOrientation,
          _productTitle: productTitle,
        };

        lineItemOptions = allContextLineItemProperties(lineItemOptions);

        addToCartShopify(lineItemOptions, startTime);
        setIgnoreImage(false);
      }
      // Handle frame with photo
      else {
        dispatch(setCartFeedbackMessage('Preparing images...'));
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        window.scrollTo(0, 0);

        // Function called when line items are done
        const callback = (lineItemOptions: any) => {
          // debug('done.... now finishing operations....', lineItemOptions);
          dispatch(setCartFeedbackMessage('Adding to cart...'));

          addToCartShopify(lineItemOptions, startTime);
        };
        dispatch(thunkCreateLineItems(callback));
      }
    }
  };

  useEffect(() => {
    const btn: HTMLButtonElement | null = document.querySelector('#fn-atc-button');
    if (btn && btn !== null && ignoreImage) {
      btn.click();
    }
  }, [ignoreImage]);

  // TODO - Remove fake frame and use the original one as I found out scrollX and scrollY fix the issues with image generation
  return (
    <form
      onSubmit={onFormSubmit}
      method="post"
      action="/cart/add"
      acceptCharset="UTF-8"
      className="shopify-product-form"
      encType="multipart/form-data"
      noValidate={true}
      data-product-form=""
      // ref={buttonRef}
    >
      {children}
      {showPreview ? <CartModal /> : null}
      <ModalEmptyPhoto
        openModal={openModal}
        onClose={() => setOpenModal(false)}
        setIgnoreImage={setIgnoreImage}
        imageRequired={imageRequired}
      />
    </form>
  );
};
Cart.displayName = 'Cart';
export default withQuery(Cart);

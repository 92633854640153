import styled from 'styled-components';

export const AccordionContainer = styled.div``;

export const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  cursor: pointer;
  font-size: 22px;
`;

export const ArrowIcon = styled.span<{ isExpanded: boolean }>`
  display: inline-flex;
  transition: transform 0.3s ease;
  transform: ${({ isExpanded }) => (isExpanded ? 'rotate(180deg)' : 'rotate(0)')};
  opacity: 0.7;
`;

export const AccordionContent = styled.div<{ isExpanded: boolean }>`
  max-height: ${({ isExpanded }) => (isExpanded ? '1500px' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease;
  padding: ${({ isExpanded }) => (isExpanded ? '0 0 25px 0' : '0')};
`;

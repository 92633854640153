import React from 'react';
import { Product } from '../../../types/ecommerce.types';
import { withQuery } from '../data/withQuery';
import { getMetafieldV2 } from '../../../utils/utils';
import * as S from './ProductDescriptionV2.styles';

const GalleryDescription: React.FC<{ product: Product }> = ({ product }) => {
  const productMetafields = product?.metafields;
  const editorialImage = getMetafieldV2('editorial_photos', productMetafields);

  if (!product?.descriptionHtml) {
    return null;
  }

  return (
    <S.GalleryWrapper>
      <div data-cy="gallery-description">
        <S.GalleryDescription>
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: product?.descriptionHtml.replace(/none;/g, 'block;'),
              }}
            />
            {editorialImage && (
              <div>
                <img src={editorialImage} alt="" />
              </div>
            )}
          </div>
        </S.GalleryDescription>
      </div>
    </S.GalleryWrapper>
  );
};

export default withQuery(GalleryDescription);

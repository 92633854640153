import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { ActionLink, BottomContainer, Divider, MainButtons } from './FrameBuilder.styles';
import Tooltip from '@/components/templates/tooltip/Tooltip';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { ProductTypes } from '@/types/ecommerce.types';

type PropsType = {
  className?: string;
  buttons: {
    leftButtons?: Buttons[];
    mainButtons?: Buttons[];
    rightButtons?: Buttons[];
  };
};

export type Buttons = {
  onClick: () => void;
  content: string;
  imageComponent: React.ReactNode;
  hideDesktop?: boolean;
  tooltipText?: string;
  children?: React.ReactNode;
};

const ActionLinkComponent: React.FC<Buttons> = ({
  onClick,
  content,
  imageComponent,
  tooltipText,
  children,
}) => (
  <ActionLink onClick={onClick}>
    {tooltipText && <Tooltip text={tooltipText} />}
    {imageComponent}
    <span className="underline-link">{content}</span>
    {children}
  </ActionLink>
);

const BottomButtons = ({ className, buttons }: PropsType) => {
  const isMobile = useMediaQuery({ maxWidth: 799 });
  const isGallery = useSelector((state: RootState) => state.product.type === ProductTypes.GALLERY);

  const renderButtons = (buttonList: Buttons[] | undefined) => {
    return buttonList?.map(
      (item) =>
        (!item.hideDesktop || isMobile) && <ActionLinkComponent key={item.content} {...item} />
    );
  };

  return (
    <BottomContainer className={className} data-hide="true" isGallery={isGallery}>
      {renderButtons(buttons?.leftButtons)}
      {isMobile && buttons?.leftButtons && buttons?.leftButtons.length > 0 && <Divider />}
      <MainButtons>{renderButtons(buttons?.mainButtons)}</MainButtons>
      {isMobile && buttons?.rightButtons && buttons?.rightButtons.length > 0 && <Divider />}
      {renderButtons(buttons?.rightButtons)}
    </BottomContainer>
  );
};

export default BottomButtons;

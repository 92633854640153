import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Product } from '../../../types/ecommerce.types';
import { RootState } from '../../../store';
import { withQuery } from '../data/withQuery';
import { ProductTypes, Metafields } from '../../../types/ecommerce.types';
import { getCornerImage, getMetafieldJSON, handleizeProductType } from '../../../utils/utils';
import * as S from './ProductDescriptionV2.styles';
import { getVideoUrls } from '@/utils/video';
import Video from '../slider/Video';
import ProductPrintDescriptionV2 from './ProductPrintDescriptionV2';

const ProductDescriptionV2: React.FC<{
  product: Product;
  tabs: React.FC;
  shopMetafields: Metafields;
}> = ({ product, tabs, shopMetafields }) => {
  const { type } = useSelector((state: RootState) => state.product);
  const isGallery = type === ProductTypes.GALLERY;
  const cornerImage = getCornerImage(product?.images?.edges);
  const videoUrls = getVideoUrls(product);
  const [sectionDisplay, setSectionDisplay] = useState();
  const productType = handleizeProductType(product?.productType);

  useEffect(() => {
    if (!!shopMetafields && !!productType) {
      const descriptionMediaParams: any = getMetafieldJSON(
        'pt_description_media_display',
        shopMetafields
      );
      setSectionDisplay(descriptionMediaParams[productType]);
    }
  }, [shopMetafields, productType]);

  if (!product?.descriptionHtml) {
    return null;
  }

  return (
    <S.Wrapper isGallery={isGallery} data-cy="product-description">
      <div data-cy="product-description-details">
        {isGallery ? tabs : null}
        <div
          dangerouslySetInnerHTML={{
            __html: product?.descriptionHtml.replace(/none;/g, 'block;'),
          }}
        />
        <div className={`${cornerImage?.includes('corner-v2') ? 'tmplt-product-corner-v2' : ''}`}>
          {videoUrls ? (
            <Video videoUrl={videoUrls} />
          ) : cornerImage ? (
            <img src={cornerImage} alt="" />
          ) : null}
        </div>
      </div>
      <ProductPrintDescriptionV2 data-cy="product-detail" sectionDisplay={sectionDisplay} />
    </S.Wrapper>
  );
};
ProductDescriptionV2.displayName = 'ProductDescriptionV2';
export default withQuery(ProductDescriptionV2);

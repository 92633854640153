import styled from 'styled-components';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import { colors } from '@/themes/colorsMapping';
import ProductHeader from '@/components/product/header/ProductHeader';
import ProductReviewMock from '@/components/product/shared/ProductReviewMock';

export const Wrapper = styled.div<{ isGallery?: boolean }>`
  height: 100%;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  height: 100%;
  @media (min-width: 800px) {
    max-width: 560px;
    height: auto;
  }
  &:after {
    bottom: 0 !important;
    background-color: transparent !important;
  }
`;

export const Container = styled.div<{
  isEditingMode: boolean;
  isGallery: boolean;
  is3dModel: boolean;
  showStyleButton: boolean;
}>`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
  @media (max-width: 800px) {
    height: ${(props) =>
      props.isGallery && props.is3dModel
        ? '111.5vw'
        : props.isGallery
        ? 'calc(111.5vw + 40px)'
        : props.showStyleButton
        ? 'auto'
        : props.isEditingMode
        ? '100vh'
        : '80vh'};
    margin-left: -20px;
    width: calc(100% + 40px);
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: 27px;
  background-color: ${colors.darkGray};
  margin: 0 10px;
`;

export const MainButtons = styled.div`
  display: flex;
  gap: 15px;
`;

export const BottomContainer = styled.div<{ isGallery?: boolean }>`
  width: 100%;
  background: ${({ isGallery }) =>
    isGallery
      ? 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 44%)'
      : ''};
  position: absolute;
  bottom: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0;
  gap: 20px;
  position: relative;
  margin: ${({ isGallery }) => (isGallery ? '20px 0' : '0 0 15px 0')};

  @media (max-width: 799px) {
    border-radius: 0;
    height: 109px;
    padding: 0 0 15px 0;
    gap: 7px !important;
    position: absolute;
    bottom: 0;
    margin: ${({ isGallery }) => (isGallery ? '20px 0' : '0')};
  }
`;

export const ActionLink = styled.button`
  display: flex;
  cursor: pointer;
  font-size: 14px;
  align-items: center;
  flex-direction: ${({ theme }) => theme.slider.direction};
  min-width: 36px;
  position: relative;
  flex-direction: column;
  justify-content: center;

  /* svg {
    fill: ${({ theme }) => theme.slider.color};
    stroke: ${({ theme }) => theme.slider.color};
  } */

  @media (min-width: 799px) {
    flex-direction: row;
    gap: 7px;
    font-size: 16px;
  }

  span {
    margin-left: ${({ theme }) => theme.slider.marginLeft}px;
  }

  .underline-link {
    margin: 0;
  }

  .underline-link:after {
    background-color: ${({ theme }) => theme.slider.color};
  }

  &::after {
    content: '';
    position: absolute;
    top: -10px;
    right: -5px;
    bottom: -10px;
    left: -5px;
    z-index: -1;
  }
`;

export const ProductContainer = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0;
  bottom: 80px;
  z-index: 3;

  h1 {
    font-size: 16px;
  }

  span {
    font-size: 14px;
  }

  @media (min-width: 800px) {
    display: none;
  }
`;

export const ProductHeaderWithSize = styled(ProductHeader)`
  margin-bottom: 0;

  h1 {
    font-family: 'futura-pt', sans-serif;
    font-weight: 600;
    margin-right: 0;
    font-size: 22px;
  }
`;

export const ProductReviewMockStyled = styled(ProductReviewMock)`
  font-size: 14px;
  line-height: 14px;
  margin-top: 5px;

  svg {
    width: 65px;

    path:last-child {
      fill: ${colors.mediumGray};
      @media (max-width: 799px) {
        fill: ${colors.blackText};
      }
    }
  }
`;

export const ModelViewerWrapper = styled.div<{ isGallery?: boolean }>`
  width: 100%;
  height: 100%;

  @media (min-width: 799px) {
    overflow: hidden;
    border-radius: 10px;
    height: 625px;
    background-color: ${({ isGallery }) => (isGallery ? colors.gray : 'transparent')};
  }

  & > div {
    height: ${({ isGallery }) => (isGallery ? '100%' : '500px')};
    @media (min-width: 799px) {
      height: 100%;
    }

    model-viewer {
      width: 106% !important;
      height: ${({ isGallery }) => (isGallery ? '434px !important' : '106% !important')};
      max-height: 500px;
      align-self: flex-start;
      @media (min-width: 800px) {
        align-self: center;
        width: 100%;
        height: 100% !important;
        max-height: 700px;
      }
    }
  }
`;

export const VerticalDivider = styled.div`
  width: 1px;
  height: 20px;
  background-color: ${colors.divider};
  margin: 0 10px;
`;

import React from 'react';
import styled from 'styled-components';
import { Product, Metafields, ProductTypes } from '../../../types/ecommerce.types';
import { withQueryOnlyData } from '../data/withQueryOnlyData';
import getProductDescription from '../shared/getProductDescription';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { getMetafieldV2 } from '@/utils/utils';

const StyledHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -20px;
  font-size: 14px;
  margin-bottom: 2px;

  @media (min-width: 800px) {
    font-size: 16px;
    width: 100%;
    margin-left: 0;
    margin-bottom: 0;
    justify-content: left;
  }
`;

const StyledList = styled.ul`
  list-style-type: none;
  display: flex;
`;

const StyledListItem = styled.li`
  margin: 5px 0;
  display: flex;
  align-items: center;

  &:not(:last-child)::after {
    content: '•';
    opacity: 0.5;
    margin: 0 3px;
  }
`;

const ProductInfoV2: React.FC<{
  product: Product;
  shopMetafields: Metafields;
}> = ({ product, shopMetafields }) => {
  const { type } = useSelector((state: RootState) => state.product);
  const isGallery = type === ProductTypes.GALLERY;
  const galleryWidth = getMetafieldV2('gallery_width_in', product?.metafields);
  const galleryHeight = getMetafieldV2('gallery_height_in', product?.metafields);
  const galleryWidthIn = galleryWidth ? Math.round(Number(galleryWidth)) : undefined;
  const galleryHeightIn = galleryHeight ? Math.round(Number(galleryHeight)) : undefined;

  if (!product) {
    return null;
  }

  const {
    color: productColor,
    size: productSize,
    printSize,
    mount: productMount,
  } = getProductDescription(product, shopMetafields);

  return (
    <StyledHeader>
      <StyledList data-id={1}>
        {isGallery ? (
          <StyledListItem data-cy="product-color">{productColor}</StyledListItem>
        ) : (
          <StyledListItem data-cy="product-mount">{productMount}</StyledListItem>
        )}
        <StyledListItem data-cy="product-size">
          {isGallery && galleryWidthIn && galleryHeightIn
            ? `${galleryWidthIn}" x ${galleryHeightIn}" `
            : null}
          {productSize}
        </StyledListItem>
        {printSize && !isGallery ? (
          <StyledListItem data-cy="print-size">{printSize}</StyledListItem>
        ) : null}
      </StyledList>
    </StyledHeader>
  );
};

ProductInfoV2.displayName = 'ProductInfoV2';
export default withQueryOnlyData(ProductInfoV2);

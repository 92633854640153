import { navigate } from '@reach/router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Metafields, Product, ProductTypes } from '@/types/ecommerce.types';
import styled from 'styled-components';
import { RootState } from '@/store';
import { setGalleryItem } from '@/store/product/productSlice';
import { setShouldCropToAll, setValidation } from '@/store/upload/uploadSlice';
import { withQuery } from '../data/withQuery';
import ImageService from '@/services/ImageService';
import { getCurrentVariant, getFrameSizeFromVariant, parseGid } from '@/utils/utils';
import { ProductOptionsTitle } from './ProductOptions.styles';
import getProductDescription from '../shared/getProductDescription';
import { colors } from '@/themes/colorsMapping';
import PriceWithDiscount from '../cart/PriceWithDiscount';

const VariantList = styled.ul`
  margin-top: 13px;
  position: relative;
  padding: 3px;
  @media (min-width: 800px) {
    margin-left: -3px;
    margin-top: 10px;
  }

  .tmplt-product__variants-label {
    border-color: rgba(0, 0, 0, 0.1);
    color: ${colors.blackText};
  }

  .tmplt-product__variants-label:hover {
    border-color: rgba(0, 0, 0, 0.3);
  }

  .tmplt-product__variants-input:checked + label {
    border-color: ${colors.purple};
    box-shadow: 0 0 0 3px rgba(51, 0, 204, 0.2);
  }
`;

const LabelStyled = styled.label`
  border-radius: 6px;
  display: flex;
  justify-content: center;
  min-height: 50px;

  div {
    font-size: 14px;
  }

  span {
    font-weight: 500;
    & + span {
      margin-top: 5px;
    }
  }
`;

type PropsType = {
  product: Product;
  showMeta?: boolean;
  className?: string;
  sectionTitle?: string;
  shopMetafields: Metafields;
  showPrice: boolean;
};

const ProductVariants = ({
  product,
  showMeta = true,
  className,
  sectionTitle,
  shopMetafields,
  showPrice = false,
}: PropsType) => {
  const dispatch = useDispatch();
  const variants = product?.variants?.edges;
  const currentVariant = getCurrentVariant(variants);

  const frameSizeInPixels = getFrameSizeFromVariant(currentVariant);
  const { type, items } = useSelector((state: RootState) => state.product);
  const imageId = useSelector((state: RootState) => state.editor.imageId);
  const newPosition = String(imageId ?? 0);
  const tile = useSelector((state: RootState) => state.upload.tiles[newPosition]);
  const originalImageSize = tile?.originalImageSize;
  const { color, size: productSize, printSize } = getProductDescription(product, shopMetafields);

  useEffect(() => {
    ImageService.validateImageQuality(originalImageSize, frameSizeInPixels, (result: boolean) => {
      if (result === false) {
        dispatch(setValidation({ qualityIsOk: false, position: newPosition }));
      } else {
        dispatch(setValidation({ isLowRes: false }));
      }
    });
  }, [currentVariant]);

  if (!product || !currentVariant || type === ProductTypes.GALLERY) {
    return null;
  }

  return (
    <div className={className}>
      {sectionTitle && (
        <ProductOptionsTitle>
          {sectionTitle}: {'\u00A0'}
          <span>{productSize}</span>
        </ProductOptionsTitle>
      )}
      <VariantList className="tmplt-product__variants-list">
        {variants?.map((variant) => {
          let checkedItem: boolean = variant.node.id === currentVariant.id;

          // The title always comes from first item of array selectedOptions
          const title = variant.node.selectedOptions[0].value;

          return (
            <li
              key={variant.node.id}
              className={`typo__paragraph--small tmplt-product__variants-item tmplt-product__variants-item--${
                variant.node?.availableForSale ? '' : 'sold-out'
              }`}
            >
              <input
                checked={checkedItem}
                type="radio"
                className="fn-variant-input tmplt-product__variants-input visually-hidden"
                id={variant.node.id}
                onChange={() => {
                  let url = `?variant=${parseGid(variant.node.id)}`;
                  navigate(url, { replace: true });
                  if (tile) {
                    dispatch(setShouldCropToAll());
                  }

                  items?.forEach((item: any, i: number) => {
                    dispatch(
                      setGalleryItem({
                        index: i,
                        obj: {
                          imageIsLoading: true,
                        },
                      })
                    );
                  });
                }}
              />
              <LabelStyled className="tmplt-product__variants-label" htmlFor={variant.node.id}>
                <span>{title}</span>
                {showPrice && <PriceWithDiscount variant={variant} />}
              </LabelStyled>
            </li>
          );
        })}
      </VariantList>
    </div>
  );
};
ProductVariants.displayName = 'ProductVariants';
export default withQuery(ProductVariants);

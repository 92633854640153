import { colors } from '@/themes/colorsMapping';
import styled from 'styled-components';
import FrameBuilder from '../frameBuilder/FrameBuilder';

const OptionsWrapper = styled.div`
  @media (min-width: 800px) {
    margin-bottom: 40px;
  }
`;
OptionsWrapper.displayName = 'OptionsWrapper';

const OptionsBlock = styled.section`
  background-color: ${colors.gray};
  margin-top: 0;
  max-height: 100%;
  @media (min-width: 800px) {
    background-color: transparent;
  }
  > ul {
    margin-top: 15px;
  }
`;
OptionsBlock.displayName = 'OptionsBlock';

const GalleryColorSwatches = styled.div`
  justify-content: center;
  display: flex;
  padding: 0 0;
  position: relative;
  margin-bottom: 0;
  margin-top: -4px;
  transform: scale(0.9);

  @media (min-width: 768px) {
    transform: scale(1);
    margin-top: 0;
    padding: 10px 0;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 40%;
    height: 1px;
    background-color: ${colors.darkGray};
    margin: 10px auto;
  }

  ul {
    margin-left: 0 !important;
    margin: 13px 0 25px 20px;

    @media (min-width: 768px) {
      margin: 13px 0 18px 20px;
    }
  }
`;

const ProducWrapper = styled.div<{ isGallery?: boolean }>`
  background-color: ${colors.gray};
  margin-bottom: 20px;
  padding-bottom: ${({ isGallery }) => (isGallery ? '0' : '0')};
  @media (min-width: 800px) {
    margin-bottom: ${({ isGallery }) => (isGallery ? '40px' : '0')};
    padding-bottom: 0;
    background-color: transparent;
  }
  @media (min-width: 1100px) {
    background: ${({ isGallery }) =>
      !isGallery ? 'linear-gradient(to right, rgba(241, 240, 236, 0.5) 55%, #fff 50%)' : ''};
  }
`;

const FrameBuilderStyled = styled(FrameBuilder)`
  .frame-builder-bottom-container {
    em {
      display: none;
    }

    ::after {
      display: none;
    }

    @media (max-width: 799px) {
      gap: 25px;
      height: 70px;

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }

  @media (max-width: 799px) {
    .tmplt-product__media {
    }
  }
`;

const MediaWrapper = styled.div<{ isGallery?: boolean }>`
  position: relative;
  top: 0;
  margin-bottom: 0;

  @media (min-width: 800px) {
    margin-right: 45px;
    max-width: 655px;

    margin-bottom: ${({ isGallery }) => (isGallery ? '0' : '30px')};
    position: sticky !important;
    top: 30px;
  }
  &:before {
    content: none;
  }
  &::after {
    content: none;
  }
`;
MediaWrapper.displayName = 'MediaWrapper';

export {
  OptionsWrapper,
  OptionsBlock,
  MediaWrapper,
  ProducWrapper,
  FrameBuilderStyled,
  GalleryColorSwatches,
};

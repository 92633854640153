import styled from 'styled-components';

const ViewerContainer = styled.div<{
  isRoomMode: boolean;
  printSize: string | undefined;
  isEditingMode: boolean;
  isGallery: boolean;
}>`
  position: relative;
  width: 100%;
  /* height: 625px; */
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: ${(props) => (props.isEditingMode ? 'visible' : 'hidden')};
  border-radius: 0;
  min-height: ${(props) => (props.isGallery ? '100%' : '558px')};
  /* margin-top: ${(props) => (props.isGallery ? '30px' : '0')}; */
  flex: 1;
  height: 100%;
  @media (min-width: 420px) and (max-width: 800px) {
    min-height: ${(props) => (props.isGallery ? '100%' : '558px')};
  }
  @media (min-width: 800px) {
    margin-top: 0;
  }
  @media (max-width: 800px) {
    padding-bottom: 100px;
  }
`;
ViewerContainer.displayName = 'ViewerContainer';

const ImageContent = styled.div<{
  isRoomMode: boolean;
  aspectRatio: number;
  printSize: string | undefined;
  orientation: string;
}>`
  padding: 40px;
  /* padding-top: ${(props) => (props.isRoomMode ? '40px' : '70px')}; */
  padding-top: 40px;
  padding-bottom: ${(props) => (props.isRoomMode ? '40px' : '15px')};
  box-sizing: border-box;
  z-index: 2;
  flex: 1 0;
  max-width: ${(props) => (props.aspectRatio < 1 ? '100%' : 'none')};
  align-self: center;
  /* height: 300px; */
  /* max-height: 50vh; */

  @media (min-width: 800px) {
    /* max-height: 573px; */
    max-width: none;
    padding-top: ${(props) => (props.isRoomMode ? '40px' : '40px')};
    padding-bottom: ${(props) => (props.isRoomMode ? '40px' : '40px')};
  }

  > div {
    /* border: 1px solid lightgreen; */
    cursor: ${(props) => (props.isRoomMode ? 'pointer' : 'default')};
    transform-origin: center;
    /* TODO: With the recent design changes this might not be needed any more */
    aspect-ratio: ${({ printSize, orientation }) => {
      if (!printSize) return 'auto';

      const [width, height] = printSize.split('x');
      if (orientation === 'portrait') {
        return `${width} / ${height}`;
      }
      return `${height} / ${width}`;
    }};

    &:hover {
      transform: ${(props) => (props.isRoomMode ? 1.05 : 1)};
    }
  }

  &[data-type='picture-frame'],
  &[data-type='metal-print'],
  &[data-type='acrylic-block'],
  &[data-type='acrylic-print'],
  &[data-type='acrylic-standoff'],
  &[data-type='framed-canvas-wrap'],
  &[data-type='canvas-wrap'],
  &[data-type='photo-tile-wrap'],
  &[data-type='photo-tile'],
  &[data-type='photo-inlay'],
  &[data-type='black-label-frame'],
  &[data-type='gloss-wood-print'],
  &[data-type='glass-print'] {
    padding-top: 70px;
    display: flex;
    flex: 1 0;
    justify-content: center;
    align-items: ${(props) => (props.isRoomMode ? 'flex-end' : 'center')};
    @media (min-width: 800px) {
      max-height: none;
      padding-top: 0;
    }
  }
`;
ImageContent.displayName = 'ImageContent';

const BottomBtns = styled.div`
  flex: 0;
  /* border-top: 1px solid rgba(0, 0, 0, 0.5); */
  padding: 0 40px 5px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 3;
  width: 100%;
  @media (min-width: 800px) {
    flex: 0 0 52px;
    padding: 0 40px 20px;
    min-height: 52px;
  }

  button {
    font-size: 18px;
    color: #3300cc; // TODO: Move color to theme
  }
  > button {
    flex: 0 0 25px;
  }
  > div {
    display: flex;
    justify-content: center;
    flex: 1;
    width: 100%;
    span {
      margin: 0 12px;
      opacity: 0.5;
    }
  }
`;
BottomBtns.displayName = 'BottomBtns';

export { ViewerContainer, ImageContent, BottomBtns };

import styled from 'styled-components';

const OptionsWrapper = styled.div``;
OptionsWrapper.displayName = 'OptionsWrapper';

const OptionsBlock = styled.section`
  margin-top: 0;
  max-height: 100%;
  padding-bottom: 3px;
  > ul {
    margin-top: 15px;
  }
`;
OptionsBlock.displayName = 'OptionsBlock';

const MediaWrapper = styled.div`
  position: relative;
  top: 0;
  margin-bottom: 15px;

  @media (min-width: 800px) {
    margin-right: 45px;
    max-width: 655px;

    margin-bottom: 15px;
    height: 625px;
  }
  &:before {
    content: none;
  }
  &::after {
    content: none;
  }
`;
MediaWrapper.displayName = 'MediaWrapper';

export { OptionsWrapper, OptionsBlock, MediaWrapper };

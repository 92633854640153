import React, { useState } from 'react';
import styled from 'styled-components';
import { scrollToReviews } from '@/utils/utils';
import Stars from '@/components/templates/icons/StarsIcon';
import useFetchReviews from '@/hooks/useFetchReviews';
import { useMediaQuery } from 'react-responsive';

type SizeTypes = {
  size?: 'normal' | 'small';
};

type PropTypes = {
  className?: string;
  size?: SizeTypes['size'];
};

const TotalWrapper = styled.div<SizeTypes>`
  display: flex;
  margin-bottom: ${({ size }) => (size === 'small' ? '0' : '5px')};
  justify-content: flex-start;
  > div {
    opacity: 0.85;
  }

  @media (min-width: 768px) {
    justify-content: flex-start;
    width: 100%;
    margin-left: 0;
    background-color: transparent;
  }
`;

const StartsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 5px 2px 0;
`;

const DescriptionWrapper = styled.div<SizeTypes>`
  font-size: ${({ size }) => (size === 'small' ? '14px' : '16px')};
  font-weight: normal;
  display: flex;
  align-items: center;
`;

const ProductReviewMock = ({ className, size = 'normal' }: PropTypes) => {
  const [count, setCount] = useState<null | number>(null);
  useFetchReviews(setCount);

  return (
    <TotalWrapper className={className} size={size}>
      <StartsWrapper className={'stars-wrapper'}>
        <Stars size={size} />
      </StartsWrapper>
      <DescriptionWrapper onClick={scrollToReviews} size={size}>
        {count !== null ? (
          <>
            <span className="review-header__number">{count}</span>
            &nbsp;
            <span>Reviews</span>
          </>
        ) : (
          <span className="review-header__number">&nbsp;</span>
        )}
      </DescriptionWrapper>
    </TotalWrapper>
  );
};
export default ProductReviewMock;

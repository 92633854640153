import { colors } from '@/themes/colorsMapping';
import styled from 'styled-components';

const MatWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  @media (min-width: 800px) {
    margin-bottom: 30px;
  }

  ul {
    display: flex;
    margin-left: 0;
    overflow: visible;
  }

  li {
    label {
      padding: 8px 11px;
    }
  }

  .tmplt-product__variants-label {
    border-color: rgba(0, 0, 0, 0.1);
    color: ${colors.blackText};
    border-radius: 6px;
  }

  .tmplt-product__variants-label:hover {
    border-color: rgba(0, 0, 0, 0.3);
  }

  .tmplt-product__variants-input:checked + label {
    border-color: ${colors.purple};
    box-shadow: 0 0 0 3px rgba(51, 0, 204, 0.2);
  }
`;
MatWrapper.displayName = 'MatWrapper';

const ProductOptionsTitle = styled.div`
  display: none;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  @media (min-width: 800px) {
    display: block;
    /* font-weight: bold; */
    margin-top: 20px;
    margin-bottom: 10px;
    /* font-size: 16px; */
  }

  > span {
    font-weight: 500;
  }
`;
ProductOptionsTitle.displayName = 'ProductOptionsTitle';

export { MatWrapper, ProductOptionsTitle };

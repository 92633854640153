import React from 'react';

const EditIconV2: React.FC = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.5 18L8.5 13L10 15.5L15.25 10.25L16.5 13"
      stroke="#262626"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="9" cy="8.5" r="1.75" stroke="#262626" strokeWidth="1.5" />
    <path
      d="M22.5 9L22.5 5C22.5 4.46957 22.2893 3.96086 21.9142 3.58579C21.5391 3.21071 21.0304 3 20.5 3L5.5 3C4.96957 3 4.46086 3.21071 4.08579 3.58579C3.71071 3.96086 3.5 4.46957 3.5 5L3.5 20C3.5 20.5304 3.71071 21.0391 4.08579 21.4142C4.46086 21.7893 4.96957 22 5.5 22L9.5 22"
      stroke="#262626"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.3084 13.376C20.4276 13.2568 20.5691 13.1622 20.7249 13.0977C20.8807 13.0332 21.0476 13 21.2162 13C21.3848 13 21.5517 13.0332 21.7075 13.0977C21.8632 13.1622 22.0048 13.2568 22.124 13.376C22.2432 13.4952 22.3378 13.6368 22.4023 13.7925C22.4668 13.9483 22.5 14.1152 22.5 14.2838C22.5 14.4524 22.4668 14.6193 22.4023 14.7751C22.3378 14.9309 22.2432 15.0724 22.124 15.1916L15.9964 21.3192L13.5 22L14.1808 19.5036L17.2446 16.4398L20.3084 13.376Z"
      stroke="#262626"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EditIconV2;

import React from 'react';
import styled from 'styled-components';
import { Product, Metafields } from '../../../types/ecommerce.types';
import { withQueryOnlyData } from '../data/withQueryOnlyData';
import getProductDescription from '../shared/getProductDescription';

const StyledHeader = styled.header`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;

  @media (min-width: 768px) {
    font-size: 16px;
    width: 100%;
    margin-left: 0;
  }
`;

const StyledList = styled.ul`
  list-style-type: none;
  display: flex;
`;

const StyledListItem = styled.li`
  margin: 5px 0;
  display: flex;
  align-items: center;

  &:not(:last-child)::after {
    content: '•';
    opacity: 0.5;
    margin: 0 3px;
  }
`;

const ProductInfo: React.FC<{
  product: Product;
  shopMetafields: Metafields;
}> = ({ product, shopMetafields }) => {
  if (!product) {
    return null;
  }

  const {
    color: productColor,
    size: productSize,
    printSize,
  } = getProductDescription(product, shopMetafields);

  return (
    <StyledHeader>
      <StyledList data-id={1}>
        <StyledListItem data-cy="product-color">{productColor}</StyledListItem>
        <StyledListItem data-cy="product-size">{productSize}</StyledListItem>
        {printSize && <StyledListItem data-cy="print-size">{printSize}</StyledListItem>}
      </StyledList>
    </StyledHeader>
  );
};

ProductInfo.displayName = 'ProductInfo';
export default withQueryOnlyData(ProductInfo);

import React from 'react';
import { Metafields, Product } from '../../../types/ecommerce.types';
import { withQuery } from '../data/withQuery';
import { getMetafieldJSON, getMetafieldV2 } from '../../../utils/utils';
import ProductTypeVideo from './ProductTypeVideo';
import * as S from './ProductDescription.styles';

const ProductAttributionDescriptionV2: React.FC<{
  shopMetafields: Metafields;
  product: Product;
}> = ({ product }) => {
  const sectionDisplay = getMetafieldV2('attribution_description_display', product?.metafields);
  const sectionTitle = getMetafieldV2('attribution_description_title', product?.metafields);
  const sectionTitleLogo = getMetafieldJSON('attribution_description_logo', product?.metafields);
  const sectionContent = getMetafieldV2('attribution_description_content', product?.metafields);
  const sectionImage = getMetafieldJSON('attribution_description_image', product?.metafields);
  const sectionVidMp4 = getMetafieldV2(
    'attribution_description_video_mp4',
    product?.metafields
  ) as string;
  const sectionVidWebm = getMetafieldV2(
    'attribution_description_video_webm',
    product?.metafields
  ) as string;
  const sectionVidPoster = getMetafieldV2(
    'attribution_description_video_poster',
    product?.metafields
  ) as string;

  if (
    !product ||
    !sectionContent ||
    !sectionDisplay ||
    (sectionDisplay && sectionDisplay === 'hide')
  ) {
    return null;
  }

  return (
    <S.Wrapper data-cy="product-description" id="attribution-description">
      <div className="tmplt-product">
        <S.FinePrint2>
          {sectionDisplay === 'image' && (
            <div>
              <img src={sectionImage ? sectionImage[0]?.src : null} alt="" />
            </div>
          )}
          {sectionDisplay === 'video' && (
            <div>
              <ProductTypeVideo
                videoMp4={sectionVidMp4}
                videoWebm={sectionVidWebm}
                videoPoster={sectionVidPoster}
              />
            </div>
          )}
          <div>
            <div>
              <p className="typo__header typo__header--large typo__header--bold">
                {sectionTitleLogo ? (
                  <img src={sectionTitleLogo[0].src} alt={sectionTitleLogo.alt || sectionTitle} />
                ) : (
                  sectionTitle || null
                )}
              </p>
              <p
                dangerouslySetInnerHTML={{
                  __html: sectionContent ? sectionContent : null,
                }}
              />
            </div>
          </div>
        </S.FinePrint2>
      </div>
    </S.Wrapper>
  );
};

export default withQuery(ProductAttributionDescriptionV2);

import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import Container from '../shared/Container';
import SectionTitle from '../shared/SectionTitle';
import MediaPreview from './MediaPreview';
import { useMediaQuery } from 'react-responsive';
import { useEmblaCarousel } from 'embla-carousel/react';
import { colors } from '@/themes/colorsMapping';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { Product, ProductTypes } from '@/types/ecommerce.types';
import PlayIcon from '@/components/templates/icons/PlayIcon';
import SearchIcon from '@/components/templates/icons/SearchIcon';
import { withQuery } from '../data/withQuery';

interface MediaItem {
  username: string;
  src: string;
  thumbnailSrc: string;
  poster?: string;
  alt: string;
  type: 'image' | 'video';
}

interface ProductMediaProps {
  product: Product;
}

const MediaContainer = styled.div<{ isGallery?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 35px 0;
  width: 100%;
  background-color: ${({ isGallery }) => (!isGallery ? colors.gray : 'transparent')};
  margin-top: ${({ isGallery }) => (isGallery ? '0' : '0')};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: ${({ isGallery }) => (isGallery ? colors.darkGray : 'none')};
    max-width: 1240px;
    margin: 0 auto;
  }

  @media (min-width: 769px) {
    padding: 50px 0;
  }
`;

const ThumbnailContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 0 20px;
  margin: 0 0 10px;

  @media (min-width: 769px) {
    margin: 30px 0 0;
    padding: 0;
  }
`;

const ThumbnailWrapper = styled.div`
  display: flex;

  @media (min-width: 769px) {
    flex-wrap: nowrap;
  }
`;

const Thumbnail = styled.div`
  flex: 0 0 70%;
  max-width: 70%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  @media (min-width: 769px) {
    flex: 0 0 23.75%;
  }

  + div {
    margin-left: 20px;
  }

  div > img,
  div > video {
    display: block;
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  &:hover div > img,
  &:hover div > video {
    transform: scale(1.05);
  }
`;

const ThumbnailImage = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 7px;
  margin-bottom: 7px;
  width: 100%;

  svg {
    cursor: pointer;
  }
`;

const PlayIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid #fff;
  border-radius: 100px;
  width: 76px;
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
`;

const SearchIconWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: #fff;
  border-radius: 100px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px 30px;
  @media (min-width: 769px) {
    padding: 0 0 0 0;
    margin-top: -10px;
    align-items: flex-start;
  }

  p {
    margin: 0 0 20px;
    max-width: 440px;

    a {
      font-weight: 500;
    }
  }
`;

const UploadButton = styled.a`
  display: inline-block;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 100px;
  font-weight: 600;
  font-size: 16px;
  transition: all 0.3s ease;
  border: 2px solid ${colors.darkGray};
  align-self: flex-start;

  &:hover {
    transform: scale(1.02);
    background-color: #fff;
  }
`;

const EmblaButton = styled.button<{ isGallery?: boolean }>`
  background-color: ${({ isGallery }) => (isGallery ? colors.gray : '#fff')};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  transform: translateY(-100%);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 0 0 2px ${colors.darkGray};
  }

  svg {
    width: 16px;
    height: 16px;
    display: flex;
  }
`;

const EmblaButtons = styled.div`
  position: relative;
  height: 1px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

const ProductMedia: React.FC<ProductMediaProps> = ({ product }) => {
  const [selectedMedia, setSelectedMedia] = useState<MediaItem | null>(null);
  const isDesktop = useMediaQuery({ minWidth: 769 });
  const { type } = useSelector((state: RootState) => state.product);
  const isGallery = type === ProductTypes.GALLERY;
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    containScroll: 'trimSnaps',
    draggable: !isDesktop,
  });

  const showcaseItems: MediaItem[] =
    product?.showcase_collection?.reference?.items?.references?.edges?.map((edge) => {
      const node = edge.node;
      const videoSrc =
        node?.media?.reference?.sources?.find((source) => source.format === 'mp4')?.url || '';
      const mediaReference = node?.media?.reference;
      const mediaContentType = mediaReference?.mediaContentType;
      return {
        username: node?.attribution?.value,
        src: mediaContentType === 'VIDEO' ? videoSrc : mediaReference.image?.url || '',
        thumbnailSrc:
          mediaContentType === 'VIDEO'
            ? mediaReference?.previewImage?.transformedSrc
            : mediaReference?.image?.transformedSrc,
        alt: mediaReference?.alt || 'Showcase item',
        type: mediaContentType === 'VIDEO' ? 'video' : 'image',
      };
    }) || [];

  const handleThumbnailClick = (item: MediaItem) => {
    setSelectedMedia(item);
  };

  const handleClosePreview = () => {
    setSelectedMedia(null);
  };

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const content = (
    <>
      <Container>
        <SectionTitle
          title="How others are styling it"
          style={{ maxWidth: isDesktop ? '100%' : 220 }}
        />
      </Container>
      <Footer>
        <p>
          Upload your picture or mention{' '}
          <a href="https://www.instagram.com/frameologynyc">@Frameologynyc</a> on Instagram for a
          shot at being showcased!
        </p>
        <UploadButton href="https://memori.typeform.com/to/msNsl6q3" target="_blank">
          Upload your photo
        </UploadButton>
      </Footer>
      {isDesktop && showcaseItems.length > 4 ? (
        <EmblaButtons>
          <EmblaButton type="button" onClick={scrollPrev} isGallery={isGallery}>
            <div className="ymal-slider__carousel-btn-icon">
              <svg className="embla__button__svg" viewBox="0 0 532 532">
                <path
                  fill="currentColor"
                  d="M355.66 11.354c13.793-13.805 36.208-13.805 50.001 0 13.785 13.804 13.785 36.238 0 50.034L201.22 266l204.442 204.61c13.785 13.805 13.785 36.239 0 50.044-13.793 13.796-36.208 13.796-50.002 0a5994246.277 5994246.277 0 0 0-229.332-229.454 35.065 35.065 0 0 1-10.326-25.126c0-9.2 3.393-18.26 10.326-25.2C172.192 194.973 332.731 34.31 355.66 11.354Z"
                />
              </svg>
            </div>
            <span className="visually-hidden">Previous</span>
          </EmblaButton>
          <EmblaButton type="button" onClick={scrollNext} isGallery={isGallery}>
            <div className="ymal-slider__carousel-btn-icon">
              <svg className="embla__button__svg" viewBox="0 0 532 532">
                <path
                  fill="currentColor"
                  d="M176.34 520.646c-13.793 13.805-36.208 13.805-50.001 0-13.785-13.804-13.785-36.238 0-50.034L330.78 266 126.34 61.391c-13.785-13.805-13.785-36.239 0-50.044 13.793-13.796 36.208-13.796 50.002 0 22.928 22.947 206.395 206.507 229.332 229.454a35.065 35.065 0 0 1 10.326 25.126c0 9.2-3.393 18.26-10.326 25.2-45.865 45.901-206.404 206.564-229.332 229.52Z"
                />
              </svg>
            </div>
            <span className="visually-hidden">Next</span>
          </EmblaButton>
        </EmblaButtons>
      ) : null}
      {showcaseItems.length ? (
        <ThumbnailContainer ref={emblaRef} className="embla">
          <ThumbnailWrapper className="embla__container">
            {showcaseItems.map((item, index) => (
              <Thumbnail
                key={index}
                onClick={() => handleThumbnailClick(item)}
                className="embla__slide"
              >
                <ThumbnailImage>
                  <img src={item.thumbnailSrc} alt={item.alt || 'Thumbnail'} />
                  {item.type === 'video' && (
                    <PlayIconWrapper>
                      <PlayIcon />
                    </PlayIconWrapper>
                  )}
                  {item.type === 'image' && (
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                  )}
                </ThumbnailImage>
                {item.username}
              </Thumbnail>
            ))}
          </ThumbnailWrapper>
        </ThumbnailContainer>
      ) : null}
    </>
  );

  return (
    <MediaContainer isGallery={isGallery} className="how-others-are-styling-it">
      {isDesktop ? <Container>{content}</Container> : content}
      {selectedMedia && (
        <MediaPreview
          src={selectedMedia.src}
          poster={selectedMedia.poster || ''}
          alt={selectedMedia.alt}
          onClose={handleClosePreview}
          username={selectedMedia.username}
          type={selectedMedia.type}
        />
      )}
    </MediaContainer>
  );
};

export default withQuery(ProductMedia);

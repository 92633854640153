import { useSelector } from 'react-redux';
import { Product, ProductVariant } from '@/types/ecommerce.types';
import { RootState } from '@/store';
import { withQuery } from '../data/withQuery';
import { applyDiscountToPrice } from '@/utils/applyDiscountToPrice';
import { ProductTypes } from '@/types/ecommerce.types';
import { formatPrice, getCurrentVariant, getHasDiscount } from '@/utils/utils';
import { ProductVariantEdge } from '@shopify/hydrogen-react/storefront-api-types';

type PropsType = {
  product?: Product;
  newPrice?: string;
  className?: string;
  variant?: ProductVariantEdge;
};
const PriceWithDiscount = ({ className, product, newPrice, variant }: PropsType) => {
  const { items, type } = useSelector((state: RootState) => state.product);
  const isGallery = type === ProductTypes.GALLERY;

  const currentVariant = variant?.node ?? getCurrentVariant(product?.variants?.edges);
  const price = newPrice ?? currentVariant?.price?.amount;
  const comparePrice = currentVariant?.compareAtPrice?.amount;
  const priceAfterDiscount = applyDiscountToPrice(price, isGallery ? items.length : 0);
  const priceAfterDiscountRounded = Math.ceil(Number(priceAfterDiscount)).toString();
  const discountedPrice = formatPrice(priceAfterDiscountRounded);
  const priceRounded = Math.ceil(price).toString();
  const formattedPrice = formatPrice(priceRounded);
  const formattedComparePrice = comparePrice ? formatPrice(comparePrice) : undefined;

  const hasDiscount = getHasDiscount(formattedPrice, discountedPrice, isGallery, items.length);

  if (isNaN(parseFloat(priceAfterDiscount))) {
    return null;
  }

  return (
    <span className={className}>
      <span
        className={`price ${
          hasDiscount || formattedComparePrice ? 'cart-ovrl__discount-price' : ''
        }`}
        style={{ marginLeft: '5px' }}
      >
        {formattedComparePrice ? formattedComparePrice : formattedPrice}
      </span>
      <span className={`new-price ${hasDiscount || formattedComparePrice ? '' : 'hide'}`}>
        {formattedComparePrice ? formattedPrice : hasDiscount ? discountedPrice : ''}
      </span>
    </span>
  );
};
PriceWithDiscount.displayName = 'PriceWithDiscount';
export default withQuery(PriceWithDiscount);

import * as React from 'react';

type ColorIconProps = {
  size?: 'small' | 'normal';
};

const ColorIcon: React.FC<ColorIconProps> = ({ size = 'normal' }) => {
  const width = size === 'small' ? 20 : 25;
  const height = size === 'small' ? 19 : 24;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1042_4532)">
        <path
          d="M8 10.5C8.82843 10.5 9.5 9.82843 9.5 9C9.5 8.17157 8.82843 7.5 8 7.5C7.17157 7.5 6.5 8.17157 6.5 9C6.5 9.82843 7.17157 10.5 8 10.5Z"
          fill="black"
        />
        <path
          d="M12.5 8.25C13.3284 8.25 14 7.57843 14 6.75C14 5.92157 13.3284 5.25 12.5 5.25C11.6716 5.25 11 5.92157 11 6.75C11 7.57843 11.6716 8.25 12.5 8.25Z"
          fill="black"
        />
        <path
          d="M17 10.5C17.8284 10.5 18.5 9.82843 18.5 9C18.5 8.17157 17.8284 7.5 17 7.5C16.1716 7.5 15.5 8.17157 15.5 9C15.5 9.82843 16.1716 10.5 17 10.5Z"
          fill="black"
        />
        <path
          d="M17.75 15C18.5784 15 19.25 14.3284 19.25 13.5C19.25 12.6716 18.5784 12 17.75 12C16.9216 12 16.25 12.6716 16.25 13.5C16.25 14.3284 16.9216 15 17.75 15Z"
          fill="black"
        />
        <path
          d="M14.75 18.75C15.5784 18.75 16.25 18.0784 16.25 17.25C16.25 16.4216 15.5784 15.75 14.75 15.75C13.9216 15.75 13.25 16.4216 13.25 17.25C13.25 18.0784 13.9216 18.75 14.75 18.75Z"
          fill="black"
        />
        <path
          d="M12.905 1.5C11.4924 1.44548 10.0834 1.67675 8.76234 2.17996C7.44131 2.68316 6.23548 3.44794 5.21716 4.42844C4.19884 5.40894 3.389 6.58497 2.83618 7.88603C2.28336 9.18708 1.99895 10.5864 2 12C1.99997 12.556 2.12818 13.1045 2.37466 13.6029C2.62115 14.1012 2.97927 14.536 3.42117 14.8735C3.86308 15.2109 4.37685 15.4418 4.92255 15.5483C5.46825 15.6548 6.03116 15.634 6.5675 15.4875L7.4075 15.255C7.74166 15.1638 8.09237 15.151 8.4323 15.2175C8.77223 15.2841 9.0922 15.4282 9.3673 15.6387C9.64239 15.8492 9.86516 16.1203 10.0183 16.431C10.1714 16.7418 10.2507 17.0836 10.25 17.43V20.25C10.25 20.8467 10.4871 21.419 10.909 21.841C11.331 22.263 11.9033 22.5 12.5 22.5C13.9136 22.5011 15.3129 22.2166 16.614 21.6638C17.915 21.111 19.0911 20.3012 20.0716 19.2828C21.0521 18.2645 21.8168 17.0587 22.3201 15.7377C22.8233 14.4166 23.0545 13.0076 23 11.595C22.8912 8.95251 21.7927 6.44755 19.9226 4.57744C18.0525 2.70733 15.5475 1.60882 12.905 1.5ZM18.5 18.4925C17.6626 19.3693 16.8027 19.7743 15.6875 20.25C14.5723 20.7257 13.9625 20.7523 12.75 20.75C12.5511 20.75 12.3603 20.671 12.2197 20.5303C12.079 20.3897 12 20.1989 12 20V17.43C12 16.9375 11.6885 16.1926 11.5 15.7377C11.3115 15.2827 10.8482 14.8482 10.5 14.5C10.1518 14.1518 9.82227 13.8696 9.3673 13.6812C8.91232 13.4927 8.42469 13.3957 7.93223 13.3957C7.59522 13.3963 7.50186 13.4405 7.17682 13.5296L6.33681 13.7621C6.02308 13.8461 5.69418 13.8568 5.37565 13.7933C5.05711 13.7298 4.75748 13.5937 4.5 13.3957C4.24252 13.1977 4.14319 12.7915 4 12.5C3.85681 12.2085 3.89021 11.8248 3.89091 11.5C3.88998 10.2878 4.02583 10.1156 4.5 9C4.97418 7.88444 5.46339 6.7155 6.33681 5.875C7.21024 5.0345 7.86705 4.43099 9 4C10.1329 3.56901 11.6938 3.17127 12.905 3.21875C15.1604 3.33622 16.403 3.90303 18 5.5C19.597 7.09696 20.8825 9.24461 21 11.5C21.0516 12.712 20.932 14.1215 20.5 15.255C20.068 16.3885 19.3452 17.6298 18.5 18.5V18.4925Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1042_4532">
          <rect width="24" height="24" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

ColorIcon.displayName = 'ColorIcon';
export default ColorIcon;

import { colors } from '@/themes/colorsMapping';
import styled from 'styled-components';

const Container = styled.div`
  padding: 0 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const ProductImageWrapper = styled.div`
  position: relative;
`;

const Wrapper = styled.section`
  border-bottom: 1px solid #ddd;
  padding: 30px 0;
  @media (min-width: 768px) {
    padding: 50px 0;
    border-top: 1px solid #ddd;
  }
`;
Wrapper.displayName = 'YmalWrapper';

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 50px;
`;
LoadingContainer.displayName = 'LoadingContainer';

const RelatedProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin: 0 auto;
  padding: 0 16px;
  justify-content: center;

  @media (min-width: 768px) {
    padding: 0;
    gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(288px, 288px));
  }

  @media (min-width: 1140px) {
    padding: 0;
  }
`;

const Article = styled.div<{ isGallery: boolean }>`
  padding-top: 5px;
  &:hover img {
    transform: translateY(-2px) scale(1.01);
  }
  figure {
    display: flex;
    justify-content: center;
  }
  img {
    max-width: 100%;
    max-height: 170px;
    transition: transform 250ms ease-in-out;

    backdrop-filter: blur(0);
    filter: drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.2)) drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.2))
      drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.2));
    -webkit-filter: drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.2))
      drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.2)) drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.2));

    filter: ${({ isGallery }) =>
      isGallery
        ? 'none'
        : 'drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.2)) drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.2)) drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.2))'};

    @media (min-width: 768px) {
      max-height: 285px;
    }
  }

  ul {
    list-style-type: none;
    display: flex;
    gap: 10px;
    margin: 15px 0;
    justify-content: center;
    font-size: 16px;
    color: #6d6d6d;
  }
  li.product-thumbnail__connected-collection-item--current,
  li:hover {
    a {
      box-shadow: 0 0 0 3px #fff, 0 0 0 4px rgba(0, 0, 0, 0.25);
    }
  }
  li a.color-white {
    border: 1px solid ${colors.darkGray};
  }
  header {
    text-align: center;
    a {
      font-size: 18px;
      font-weight: 600;
    }
    span {
      justify-content: center;
      color: #6d6d6d;
      font-size: 16px;
    }
  }
`;

export { Wrapper, LoadingContainer, RelatedProductsGrid, Container, Article, ProductImageWrapper };

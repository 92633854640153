import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import Loading from '@/components/templates/icons/Loading';

interface MediaPreviewProps {
  src: string;
  poster: string;
  alt?: string;
  onClose: () => void;
  username: string;
  type: 'image' | 'video';
}

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const growIn = keyframes`
  from { transform: scale(0.9); }
  to { transform: scale(1); }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  animation: ${fadeIn} 0.2s ease-out forwards;

  @media (min-width: 769px) {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
`;

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transform: scale(0.9);
  animation: ${growIn} 0.2s ease-out forwards;
  padding: 30px;
  box-sizing: border-box;

  @media (min-width: 769px) {
    width: auto;
    height: auto;
    min-width: 300px;
    min-height: 300px;
    background-color: #fff;
    padding: 4px;
    border-radius: 4px;
  }
`;

const PreviewMedia = styled.div`
  max-width: 100%;
  max-height: calc(100vh - 120px);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s ease-out;

  @media (min-width: 769px) {
    width: 100%;
    height: 100%;
    min-width: 300px;
    min-height: 300px;
  }
`;

const PreviewImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const PreviewVideo = styled.video`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const Username = styled.p`
  margin-top: 15px;
  font-size: 16px;
  font-weight: 500;
  transition: opacity 0.2s ease-out;
  color: #000000;

  @media (min-width: 769px) {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: #ffffff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  }
`;

const CloseButton = styled.button`
  position: fixed;
  top: 20px;
  right: 20px;
  background: transparent;
  border: none;
  color: #000000;
  font-size: 30px;
  cursor: pointer;
  opacity: 0;
  transition: all 0.2s ease-out;
  z-index: 1001;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 100px;

  @media (min-width: 769px) {
    top: 30px;
    right: 30px;
    color: #ffffff;
    font-size: 40px;
    width: 60px;
    height: 60px;
    border: 1px solid rgba(255, 255, 255, 0.5);

    &:hover {
      transform: scale(1.1);
    }
  }
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  min-height: 300px;
`;

const MediaPreview: React.FC<MediaPreviewProps> = ({
  src,
  poster,
  alt,
  onClose,
  username,
  type,
}) => {
  const [mediaLoaded, setMediaLoaded] = useState(false);
  const mediaRef = useRef<HTMLImageElement | HTMLVideoElement>(null);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleMediaLoad = () => {
    setMediaLoaded(true);
  };

  const renderMedia = () => {
    if (type === 'video') {
      return (
        <PreviewVideo
          ref={mediaRef as React.RefObject<HTMLVideoElement>}
          src={src}
          poster={poster}
          controls
          autoPlay
          style={{ opacity: mediaLoaded ? 1 : 0 }}
          onLoadedData={handleMediaLoad}
        />
      );
    }

    return (
      <>
        {!mediaLoaded && (
          <LoadingWrapper>
            <Loading size="large" />
          </LoadingWrapper>
        )}
        <PreviewImage
          ref={mediaRef as React.RefObject<HTMLImageElement>}
          src={src}
          alt={alt}
          style={{ opacity: mediaLoaded ? 1 : 0 }}
          onLoad={handleMediaLoad}
        />
      </>
    );
  };

  return (
    <Overlay onClick={onClose}>
      <CloseButton onClick={onClose} style={{ opacity: mediaLoaded ? 1 : 0 }}>
        &times;
      </CloseButton>
      <PreviewContainer onClick={(e) => e.stopPropagation()}>
        <PreviewMedia>{renderMedia()}</PreviewMedia>
        <Username style={{ opacity: mediaLoaded ? 1 : 0 }}>{username}</Username>
      </PreviewContainer>
    </Overlay>
  );
};

export default MediaPreview;
